import { useMediaQuery } from "../hooks"

export const TitleTopBar = ({children}) => {

    const isSmallMobile = useMediaQuery('(min-width: 550px)')

    return (
        <p className={`${isSmallMobile ? "text-[14px] md:text-[20px]" : "text-[12px]"} dark:text-darkwhite text-textblack font-strong transition-all ease duration-100`}>{children}</p>
    )
}

export const TextDarkStrong = ({children}) => {

    const isSmallMobile = useMediaQuery('(min-width: 550px)')

    return (
        <p className={`${isSmallMobile ? "text-[11px] md:text-[11px]" : "text-[9px]"} text-textgray font-strong transition-all ease duration-100`}>{children}</p>
    )
}

export const TextTopBar = ({children}) => {
    return (
        <p className="text-[12px] md:text-[16px] dark:text-darklightgray text-textblack tracking-tight font-medium">{children}</p>
    )
}

export const Title = ({children}) => {
    return (
        <p className="text-[1.1rem] dark:text-darkwhite text-textblack font-strong">{children}</p>
    )
}

export const LightTitle = ({children}) => {
    return (
        <p className="text-[1.25rem] text-textgray dark:text-darklightgray font-strong pb-2">{children}</p>
    )
}

export const TextBlackBold = ({children}) => {
    return (
        <p className="text-[0.9rem] text-textblack dark:text-darklightgray font-normal font-bold">{children}</p>
    )
}

export const TextBlack = ({children}) => {
    return (
        <p className="text-[0.7rem] md:text-[0.9rem] dark:text-darkwhite text-textblack font-normal">{children}</p>
    )
}

export const TextSmallBlack = ({children}) => {
    return (
        <p className="text-[0.7rem] md:text-[0.8rem] dark:text-darklightgray text-textblack font-normal">{children}</p>
    )
}

export const TextBlackFixed = ({children}) => {
    return (
        <p className="text-[0.9rem] dark:text-darklightgray text-textblack font-normal">{children}</p>
    )
}

export const TextDark = ({children}) => {

    const isSmallMobile = useMediaQuery('(min-width: 550px)')

    return (
        <p className={`${isSmallMobile ? "text-[0.8rem] md:text-[0.9rem]" : "text-[0.7rem] md:text-[0.9rem]"} dark:text-darklightgray text-textdark font-normal`}>{children}</p>
    )
}

export const TextGrayBold = ({children}) => {
    return (
        <p className="text-[0.9rem] text-textgray font-normal font-bold">{children}</p>
    )
}

export const TextGray = ({children}) => {

    const isSmallMobile = useMediaQuery('(min-width: 550px)')

    return (
        <p className={`${isSmallMobile ? "text-[0.9rem]" : "text-[0.7rem]"} text-textgray`}>{children}</p>
    )
}

export const TextSmallGray = ({children}) => {

    const isSmallMobile = useMediaQuery('(min-width: 550px)')

    return (
        <p className={`${isSmallMobile ? "text-[0.8rem]" : "text-[0.7rem]"} text-textgray`}>{children}</p>
    )
}

export const TextWhite = ({children}) => {
    return (
        <p className="text-[0.7rem] dark:text-darkblack md:text-[0.9rem] text-textwhite">{children}</p>
    )
}

export const TableTextHeader = ({children}) => {
    return (
        <th className="h-[32px] text-left dark:border-darktableborder border-x pl-2 pr-1 pt-1 border-bgray border-b-[3px]">
            <p className="text-[0.7rem] md:text-[0.9rem] dark:text-darkwhite text-textblack font-normal font-bold">{children}</p>
        </th>
    )
}

export const TableTextCell = ({children}) => {
    return (
        <td className="h-[32px] dark:border-darktableborder border px-2 border-bgray focus:bg-bggray">
            <p className="text-[0.8rem] md:text-[0.9rem] dark:text-darklightgray text-textblack font-normal">{children}</p>
        </td>
    )
}