import { useState } from 'react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import moment from "moment-timezone"

export const ProgressBar = ({lastDate, value, start, status, detailStatus, prevStatus}) => {

    const [openDetail, setOpenDetail] = useState(false)

    const progress = (value != 0) ? value + "%" : prevStatus
    const initialTime = moment(start, "DD/MM/YYYY HH:mm:ss")
    const lastTime = moment()

    const duration = lastTime.diff(initialTime, "seconds")
    const seconds = duration * 100 / value
    const minutes = Number(seconds / 60).toFixed(0)
    const hours = Number(minutes / 60).toFixed(0)
    const fixMinutes = minutes - (hours * 60)
    let textToEnd
    textToEnd = (hours > 0) ? (hours == 1) ? "1 hora " : `${hours} horas ` : ""
    textToEnd += (fixMinutes > 0) ? (fixMinutes == 1) ? "1 minuto " : `${fixMinutes} minutos ` : ""
    const timeToEnd = (textToEnd != "" && status == "En Ejecución") ? " - Tiempo restante aproximado: " + textToEnd : ""

    return (
        <td className="border px-2 border-bgray focus:bg-bggray items-center min-w-[300px] max-w-[400px]">
            <div className="text-base text-[0.7rem] md:text-[0.8rem] text-textdark dark:text-darklightgray inline-flex font-normal mx-4">
                {
                !openDetail 
                ? <KeyboardArrowDownIcon className="cursor-pointer" onClick={() => setOpenDetail(true)} sx={{color:"#999", marginTop:"3px"}} fontSize="small"/>
                : <KeyboardArrowUpIcon className="cursor-pointer" onClick={() => setOpenDetail(false)} sx={{color:"#999", marginTop:"3px"}} fontSize="small"/>
                }
                
                <p className="ml-2 mt-1 leading-none text-textdark dark:text-darklightgray md:text-[0.8rem] text-[0.7rem] font-bold">Progreso: <span className="font-normal md:text-[0.8rem] text-[0.7rem]">{progress + timeToEnd}</span></p>
            </div>
            <div className={`${openDetail ? "h-auto mb-2" : "h-0 my-0" } ml-7 overflow-hidden transition-all ease duration-200`}>
                <div className="text-base text-textdark dark:text-darklightgray font-normal mx-4">
                    <p className="text-textdark dark:text-darklightgray md:text-[0.8rem] text-[0.7rem] font-bold">Última actualización: <span className="md:text-[0.8rem] text-[0.7rem] font-normal">{moment.utc(lastDate, "DD/MM/YYYY HH:mm:ss").tz("America/Santiago").local().format("DD [de] MMMM YYYY HH:mm")}</span></p>
                </div>
                <div className="text-base leading-none text-textdark dark:text-darklightgray font-normal mx-4">
                    <p className="text-textdark dark:text-darklightgray md:text-[0.8rem] text-[0.7rem] font-bold">Estado anterior: <span className="md:text-[0.8rem] text-[0.7rem] font-normal">{prevStatus}</span></p>
                </div>
                <div className="text-base leading-none text-textdark dark:text-darklightgray font-normal mx-4">
                    <p className="text-textdark dark:text-darklightgray md:text-[0.8rem] text-[0.7rem] font-bold mt-1">Detalle: <span className="md:text-[0.8rem] text-[0.7rem] font-normal">{detailStatus}</span></p>
                </div>
            </div>
            <div className="w-full bg-[#ddd] rounded-full h-2 mb-2">
                <div className={`${status == "En Ejecución" ? "bg-bgblue" : status == "Error" ? "bg-bgred" : "bg-bggreen"} h-2 rounded-full text-[0.8rem] text-textwhite text-center`} style={{width: `${value != 0 ? value + "%" : "0px"}`}}></div>
            </div>   
        </td>
    )
}