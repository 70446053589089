import moment from "moment"

export const circularGraphicsAdapter = (data) => {
    let formattResponse = Object.entries(data.data).map(([name, y]) => ({ name, y }))
    formattResponse = formattResponse.filter(el => el.y > 0)
    formattResponse.sort((a, b) => b.y - a.y)
    formattResponse[0].sliced = true
    formattResponse[0].selected = true
    return formattResponse
}

export const linearGraphicsAdapter = (data) => {
    
    const series = Object.keys(data.data)

    let formattResponse = []
    for (let serie of series) {

        let serieFormattData = []
        for (let i = 0; i < 12; i++) {
            const date = + new Date(Number(moment().subtract(12 - i, 'month').format("YYYY")), Number(moment().subtract(12 - i, 'month').format("M")) - 1)
            serieFormattData.push({x: date, y: null, date: moment().subtract(12 - i, 'month').format("YYYYMM")})
        }

        let serieData = Object.entries(data.data[serie]).map(([date, size]) => ({ date, size }))
        for (let elem of serieData) {
            if (elem.date != "last_backup") {
                const index = serieFormattData.findIndex(e => e.date == elem.date)
                if (index != -1) serieFormattData[index].y = elem.size
            }
        }

        formattResponse.push({
            name: serie, data: serieFormattData
        })
    }

    return formattResponse
}

export const barGraphicsAdapter = (data) => {

    const series = Object.keys(data.data)

    let formattResponse = []
    for (let serie of series) {
        formattResponse.push({
            name: serie,
            y: data.data[serie].last_backup || 0,
            drilldown: serie
        })
    }

    return formattResponse
}

export const storageSizeAdapter = (data) => {

    const { s3_class_sizes, storage_gateway_status } = data.data
    const formattResponse = {
        size_s3: (s3_class_sizes.StandardIAStorage + s3_class_sizes.StandardStorage).toFixed(1) || "--", 
        size_glacier: (s3_class_sizes.DeepArchiveStorage + s3_class_sizes.GlacierStorage).toFixed(1) || "--", 
        health: storage_gateway_status == "RUNNING" ? "RUNNING" : "STOPPED!"
    }
    return formattResponse
}