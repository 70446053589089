import { useState } from 'react'
import { useFetchLoad, useAsync, useMediaQuery } from '../../hooks'
import { TableTextHeader, TableTextCell, TextBlack, TextGray } from '../Texts.component'
import { SecondaryButtonCircle } from '../Buttons.component'
import { getBackupsList, restoreBackups } from '../../services/backups.service'
import { backupsAdapter } from '../../adapters/backups.adapter'
import SwapVertIcon from '@mui/icons-material/SwapVert'
import RestoreIcon from '@mui/icons-material/Restore'
import LogoutIcon from '@mui/icons-material/Logout'
import SearchIcon from '@mui/icons-material/Search'
import DownloadIcon from '@mui/icons-material/Download'
import StorageIcon from '@mui/icons-material/Storage'
import RefreshIcon from '@mui/icons-material/Refresh'
import { Pagination } from '../Pagination.component'
import { CSVLink } from "react-csv"
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'

export const BackupsTable = ({numberRows, database = null}) => {

    const isMobile = useMediaQuery()
    const policies = useSelector(state => state.appPolicies)

    // Call backups data
    const [loading, callToFetch] = useFetchLoad()
    
    const [backups, setBackups] = useState([])
    const [page, setPage] = useState(0)
    const [itemsPerPage, setItemsPerPage] = useState(numberRows)
    const [rows, setRows] = useState(0)
    
    const getApiData = async () => await callToFetch(getBackupsList(database))
    const adaptBackups = data => {
        const [totalCount, records] = backupsAdapter(data)
        setBackups(records)
        setBackupsB(records)
        setRows(totalCount)
    }
    useAsync(getApiData, adaptBackups, () => {}, [])
    
    // Backups table data functions
    const [orderSort, setOrderSort] = useState(true)
    const sortBackupsTable = column => {
        (orderSort)
        ? setBackups([...backups].sort((a, b) => (a[column] > b[column]) ? 1 : ((b[column] > a[column]) ? -1 : 1)))
        : setBackups([...backups].sort((a, b) => (b[column] > a[column]) ? 1 : ((a[column] > b[column]) ? -1 : 1)))
        setOrderSort(!orderSort)
    }
    
    const [backupsB, setBackupsB] = useState([])
    const [textSearchBackups, setTextSearchBackups] = useState("")
    const searchBackupsTable = e => {
        setPage(0)
        setBackups(backupsB.filter(obj => Object.keys(obj).some(field => obj[field].toString().toLowerCase().includes(e.target.value.toLowerCase()))))
        setTextSearchBackups(e.target.value)
    }

    const onRestoreBackup = async (backup) => {
        const backupRestore = backups.findIndex(b => b.id == backup.id)
        const newBackups = [...backups]
        newBackups[backupRestore].status = "En proceso"
        setBackups(newBackups)
        await restoreBackups(backup)
    }

    return (
        <div className="bg-bggray dark:bg-darkdark">
            <div className="h-[60px] p-2 inline-flex items-center gap-4 flow-root">
                <div className="float-right inline-flex mt-1 gap-4 items-center mr-1">
                    <div>
{/*                         <input className="focus:outline-none focus:border-b focus:border-bblack mx-2 bg-bggray border-b border-bgray text-textblack" type="date" value={dateSearchBackups.start} onChange={searchBackupsTable}/>
                        <input className="focus:outline-none focus:border-b focus:border-bblack mx-2 bg-bggray border-b border-bgray text-textblack" type="date" value={dateSearchBackups.end} onChange={searchBackupsTable}/> */}
                        <SearchIcon sx={{color:"#666666"}} fontSize="small"/>
                        <input placeholder="Buscar" className="focus:outline-none dark:bg-darkdark dark:text-darklightgray focus:border-b focus:border-bblack mx-2 bg-bggray border-b border-bgray text-textblack" type="text" value={textSearchBackups} onChange={searchBackupsTable}/>
                    </div>
                        <CSVLink data={backups} filename={"backups_"+ moment().format("DDMMYY_HHmm") + ".csv"}>
                            <SecondaryButtonCircle>
                                <DownloadIcon className="text-[#333] dark:text-darklightgray" sx={{fontSize:16}}/>
                            </SecondaryButtonCircle> 
                        </CSVLink>
                </div>
            </div>
            <div className={`w-auto ${!isMobile && " overflow-x-scroll"}`}>
                <table className="min-w-[600px] w-full border-collapse table-auto dark:bg-darkhardgray bg-bgwhite">
                    <thead>
                        <tr>
                            <TableTextHeader>FECHA PUBLICACIÓN<SwapVertIcon onClick={() => sortBackupsTable("start_date")} className="float-right hover:text-textblack cursor-pointer" sx={{color:"#999999"}} fontSize="small"/></TableTextHeader>
                            <TableTextHeader>IDENTIFICADOR<SwapVertIcon onClick={() => sortBackupsTable("id")} className="float-right hover:text-textblack cursor-pointer" sx={{color:"#999999"}} fontSize="small"/></TableTextHeader>
                            <TableTextHeader>BASE DE DATOS<SwapVertIcon onClick={() => sortBackupsTable("database")} className="float-right hover:text-textblack cursor-pointer" sx={{color:"#999999"}} fontSize="small"/></TableTextHeader>
                            <TableTextHeader>ESPACIO EN DISCO<SwapVertIcon onClick={() => sortBackupsTable("size")} className="float-right hover:text-textblack cursor-pointer" sx={{color:"#999999"}} fontSize="small"/></TableTextHeader>
                            <TableTextHeader>ESTADO<SwapVertIcon onClick={() => sortBackupsTable("status")} className="float-right hover:text-textblack cursor-pointer" sx={{color:"#999999"}} fontSize="small"/></TableTextHeader>
                            {!!policies.find(policy => policy == 'Restore') && <TableTextHeader>ACCIONES</TableTextHeader>}
                        </tr>
                    </thead>
                    <tbody>
                        {
                            backups.map((backup, i) => (i >= (page * itemsPerPage) && i < (page * itemsPerPage) + itemsPerPage) && (
                                <tr key={i} className={"odd:bg-bgtablecell dark:bg-darkhardgray dark:odd:bg-darkdark hover:bg-bggray dark:hover:bg-darkblack"}>
                                    <TableTextCell>{moment(backup.start_date, "YYYY-MM-DD").format("DD [de] MMMM YYYY")}</TableTextCell>
                                    <TableTextCell>{backup?.id}</TableTextCell>
                                    <td className="border dark:border-darktableborder border-bgray px-2 text-center">
                                        <div className="flex mx-auto mt-1">
                                            <Link to={"/details/" + backup.database} replace>
                                                <div className="inline-flex gap-1 cursor-pointer">
                                                    <StorageIcon sx={{color:"#999", marginRight:"5px"}} fontSize="small"/>
                                                    <TextBlack>{backup?.database}</TextBlack>
                                                </div>
                                            </Link>
                                        </div>
                                    </td>
                                    <TableTextCell>{backup.size !== 0 ? `${backup.size} GB` : console.log(`${backup.size} KB`)}</TableTextCell>
                                    <TableTextCell>{backup?.status}</TableTextCell>
                                    {!!policies.find(policy => policy == 'Restore') && <td className="border dark:border-darktableborder border-bgray px-2 text-center">
                                        <div className="flex justify-center mx-auto">
                                            {(backup.status == "Archivado" || backup.status == "Desmontado")
                                            && <div onClick={() => onRestoreBackup(backup)} className="inline-flex gap-1 cursor-pointer items-end">
                                                <RestoreIcon sx={{color:"#666"}} fontSize="small"/>
                                                <TextBlack>Restaurar</TextBlack>
                                            </div>}
                                            {backup.status == "Restaurado" && <div onClick={() => onRestoreBackup(backup)} className="inline-flex gap-1 cursor-pointer items-end">
                                                <LogoutIcon sx={{color:"#666"}} fontSize="small"/>
                                                <TextBlack>Desmontar</TextBlack>
                                            </div>}
                                            {backup.status == "En proceso" && <div className="inline-flex gap-1 cursor-not-allowed items-end">
                                                <RefreshIcon sx={{color:"#ccc"}} fontSize="small" className='animate-spin'/>
                                                <TextGray>Procesando</TextGray>
                                            </div>}
                                        </div>
                                    </td>}
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
            <Pagination items={textSearchBackups != "" ? backups.length : rows} page={page} setPage={setPage} itemsPerPage={itemsPerPage}/>
        </div>
    )
}