import { TopBar, SubTopBar, SideBarLeft, Body } from '../../components/Layout'
import { LightTitle } from '../../components/Texts.component'
import { HomeContentBackups } from './components/DetailsContentBackups.component'
import { HomeContentRestoration } from './components/DetailsContentRestorations.component'
import { ContentDetails } from './components/ContentDetails.component'
import { useParams } from 'react-router-dom'

const Details = () => {

    const { database } = useParams()
    
    return (
        <>
            <TopBar/> 
            <SideBarLeft/>
            <SubTopBar>{"Inicio > Detalles"}</SubTopBar>
            <Body>
                <LightTitle>Resumen</LightTitle>
                <ContentDetails database={database}/>
                <div className="my-14"></div>
                <LightTitle>Respaldos</LightTitle>
                <HomeContentBackups database={database}/>
                <div className="my-14"></div>
                <LightTitle>Restauraciones</LightTitle>
                <HomeContentRestoration database={database}/>
            </Body>
        </>
    )
}

export default Details