import { configureStore } from '@reduxjs/toolkit'
import usersModalReducer from './states/usersModalSlice'
import rolesModalReducer from './states/rolesModalSlice'
import appStateReducer from './states/appStateSlice'
import appPoliciesReducer from './states/appPoliciesSlice'

export const store = configureStore({
    reducer: {
        appState: appStateReducer,
        appPolicies: appPoliciesReducer,
        usersModal: usersModalReducer,
        rolesModal: rolesModalReducer,
    }
})

