import Highcharts from "highcharts/highstock"
import HighchartsReact from "highcharts-react-official"
import { useFetchLoad, useAsync } from '../../hooks'
import { getBackupsSizeGraphics } from "../../services/metrics.service"
import { linearGraphicsAdapter } from "../../adapters/metrics.adapter"
import { useState } from "react"

export const LinearGraphicBackups = () => {

  	const [loading, callToFetch] = useFetchLoad()
    const [chartOptions, setChartOptions] = useState()

    const getApiData = async () => await callToFetch(getBackupsSizeGraphics())
    const adaptBackups = data => setChartOptions({
		chart: { type: 'spline', height: 300, backgroundColor: 'transparent' },
		title: null,
		xAxis: { type: 'datetime' },
        legend: { itemStyle: { color: "#999" } },
        accessibility: { enabled: false },     
        yAxis: { title: null }, 
        tooltip: {
            headerFormat: '<span style="font-size:11px">Base de datos</span><br>',
            pointFormat: '<span style="color:{point.color}">{series.name}</span>: <b>{point.y:.2f}GB</b><br/>'
          },
		series: linearGraphicsAdapter(data)
	})
    useAsync(getApiData, adaptBackups, () => {}, [])

    return (
        <>
            <HighchartsReact highcharts={Highcharts} options={chartOptions} />
        </>
    )
}