import { Title, TextBlack, TextWhite, TextGray } from "../../../components/Texts.component"
import { PrimaryButton, SecondaryButton } from "../../../components/Buttons.component"
import { useDispatch, useSelector } from 'react-redux'
import { useFetchLoad, useAsync, useMediaQuery } from '../../../hooks'
import { getRolesList } from '../../../services/roles.service'
import { addUsers, editUsers } from '../../../services/users.service'
import { setUsersModal } from '../../../redux/states/usersModalSlice'
import { rolesAdapter } from '../../../adapters/roles.adapter'
import { useState } from 'react'

export const UsersModal = ({updateData}) => {

    const isMobile = useMediaQuery('(min-width: 1024px)')
    const isTablet = useMediaQuery('(min-width: 620px)')

    // Handler modal
    const { modalDataIsNewUser, userData } = useSelector(state => state.usersModal)
    
    const dispatch = useDispatch()
    const closeModal = () => {
        const data = {
            showModalData: false,
            showModalDeleteConfirm: false,
            modalDataIsNewUser: true,
            userData: {
                id: "",
                username: "",
                email: "",
                role_id: "",
                role_name: "",
                create_date: ""
            }
        }
        dispatch(setUsersModal(data))
    }

    // Call roles list
    const [loading, callToFetch] = useFetchLoad()
    const [roles, setRoles] = useState([])

    const getApiDataRoles = async () => await callToFetch(getRolesList())
    const adaptRoles = (data) => {
        setRoles(rolesAdapter(data))
    }
    useAsync(getApiDataRoles, adaptRoles, () => {}, [])

    // Handler create and update user
    const [form, setForm] = useState({...userData})

    const submit = async () => {
        closeModal()
        const response = (modalDataIsNewUser) 
        ? await callToFetch(addUsers(form))
        : await callToFetch(editUsers(userData.id, form))
        const responseData = await response.data.message
        updateData()
    }

    return (
        <>
            <div onClick={e => closeModal()} className="bg-bgmodal w-full h-screen left-0 top-0 absolute z-40"></div>
            <div className={`${isTablet ? isMobile ? "w-[800px]" : "w-[600px]" : "w-[400px]"} absolute mt-20 translate-x-[-50%] left-2/4 rounded  h-[600px] dark:bg-darkhardgray bg-bgwhite p-5 z-50`}>
                <div>
                    <Title>{modalDataIsNewUser ? "Nuevo Usuario" : "Editar Usuario"}</Title>
                    <hr className="border-bgray my-4"/>
                </div>

                <form onSubmit={() => submit()}>
                    <div className="mt-10">
                        <TextGray>Nombre</TextGray>
                        <input 
                            className="mb-10 w-full dark:bg-darkhardgray placeholder:text-textdark placeholder:text-[1.1rem] focus:outline-none focus:border-b focus:border-bblue border-b border-bgray" 
                            placeholder="Ingresar nombre" 
                            type="text" 
                            value={form.username} 
                            onChange={e => setForm({...form, username:e.target.value})}
                            required
                        />
                        <TextGray>Email</TextGray>
                        <input 
                            className="mb-10 w-full dark:bg-darkhardgray placeholder:text-textdark placeholder:text-[1.1rem] focus:outline-none focus:border-b focus:border-bblue border-b border-bgray" 
                            placeholder="Ingresar email (nombre.apellido@inacap.cl)" 
                            type="email" 
                            value={form.email} 
                            onChange={e => setForm({...form, email:e.target.value})}
                            pattern="^[A-Za-z0-9._%+-]+@inacap\.cl$"
                            required
                        />
                        <TextGray>Rol</TextGray>
                        <select
                            onChange={e => setForm({...form, role_id:e.target.value})}
                            value={form.role_id}
                            className="invalid:text-textdark dark:bg-darkhardgray invalid:text-[1.1rem] mb-56 w-full focus:outline-none focus:border-b focus:border-bblue border-b border-bgray" 
                            required
                        >
                            <option value="">Seleccionar rol</option>
                            {roles.map(rol => (
                                <option key={rol.id} value={rol.id}>{rol.role_name}</option>
                            ))}
                        </select>
                    </div> 

                    <div className="grid justify-items-end">
                        <div className="inline-flex gap-2">
                            <SecondaryButton onClick={e => closeModal()}>
                                <TextBlack>Cancelar</TextBlack>
                            </SecondaryButton>
                            <PrimaryButton type={"submit"}>
                                <TextWhite>Guardar</TextWhite>
                            </PrimaryButton> 
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}