import { useMediaQuery } from "../hooks"

export const PrimaryButton = ({children, onClick, type}) => {

    return (
        <button onClick={onClick} type={type} className="bg-bgdark dark:bg-darklightgray gap-2 inline-flex items-center rounded-[18px] w-auto h-[36px] px-5 shadow-soft cursor-pointer hover:shadow-normal">
            {children}
        </button>
    )
}

export const SecondaryButton = ({children, onClick}) => {
    return (
        <button onClick={onClick} className="bg-bggray gap-2 dark:bg-[#666] inline-flex items-center rounded-[18px] w-auto h-[36px] px-5 shadow-soft  cursor-pointer hover:shadow-normal">
            {children}
        </button>
    )
}

export const PrimaryButtonCircle = ({children, onClick, type}) => {

    const isMobile = useMediaQuery()

    return (
        <button onClick={onClick} type={type} 
            className={`${isMobile ? "px-5 w-auto" : "justify-center w-[36px] h-[36px]"} dark:bg-darklightgray bg-bgdark gap-2 inline-flex items-center rounded-[18px] shadow-soft cursor-pointer hover:shadow-normal`}
        >
            {children}
        </button>
    )
}

export const SecondaryButtonCircle = ({children, onClick}) => {
    return (
        <button onClick={onClick} className="dark:bg-darkdark dark:border-darklightgray border-bgdark border-[2px] bg-bggray inline-flex items-center rounded-[19px] w-[38px] h-[38px] pl-[9px] pt-[1px] cursor-pointer shadow-soft hover:shadow-normal">
            {children}
        </button>
    )
}

export const TopBarButton = ({children, onClick}) => {

    const isSmallMobile = useMediaQuery('(min-width: 550px)')

    return (
        <button onClick={onClick} 
            className={`${isSmallMobile ? "px-2 border-bgray border" : ""} dark:bg-darkdark dark:border-darktableborder bg-bggray justify-center inline-flex items-center rounded-[19px] w-auto h-[30px] cursor-pointer hover:shadow-normal`}
        >
            {children}
        </button>
    )
}