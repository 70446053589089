 import axios from 'axios'

 export const AddAuthorizationToken = () => {

    axios.interceptors.request.use(
        (request) => {

            if (!request.headers["Authorization"]) {
                const token = JSON.parse(sessionStorage.getItem("session_token"))
                request.headers = {...request.headers, 'Authorization' : 'Bearer ' + token.id_token}
            }
            
            if (!request.headers["Content-Type"]) {
                request.headers = {...request.headers, "Content-Type" : "application/json"}
            }

            if (request.headers["Authorization"] == "none") {
                delete request.headers["Authorization"]
            }

            return request
        }
    )
 }