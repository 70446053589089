import { useState } from 'react'
import { useFetchLoad, useAsync, useMediaQuery } from '../../../hooks'
import { TableTextHeader, TableTextCell } from '../../../components/Texts.component'
import { SecondaryButtonCircle } from '../../../components/Buttons.component'
import { getLogsList } from '../../../services/logs.service'
import { logsAdapter } from '../../../adapters/logs.adapter'
import SwapVertIcon from '@mui/icons-material/SwapVert'
import SearchIcon from '@mui/icons-material/Search'
import DownloadIcon from '@mui/icons-material/Download'
import { Pagination } from '../../../components/Pagination.component'
import { CSVLink } from "react-csv"
import moment from 'moment-timezone'

export const LogsTable = () => {

    const isMobile = useMediaQuery()

    // Call logs data
    const [loading, callToFetch] = useFetchLoad()
    
    const [logs, setLogs] = useState([])
    const [page, setPage] = useState(0)
    const [itemsPerPage, setItemsPerPage] = useState(12)
    
    const getApiData = async () => await callToFetch(getLogsList())
    const adaptLogs = (data) => {
        setLogs(logsAdapter(data))
        setLogsB(logsAdapter(data))
    }
    useAsync(getApiData, adaptLogs, () => {}, [])
    
    // Logs table data functions
    const [orderSort, setOrderSort] = useState(true)
    const sortLogsTable = (column) => {
        (orderSort)
        ? setLogs([...logs].sort((a, b) => (a[column] > b[column]) ? 1 : ((b[column] > a[column]) ? -1 : 0)))
        : setLogs([...logs].sort((a, b) => (b[column] > a[column]) ? 1 : ((a[column] > b[column]) ? -1 : 0)))
        setOrderSort(!orderSort)
    }
    
    const [logsB, setLogsB] = useState([])
    const [textSearchLogs, setTextSearchLogs] = useState("")
    const [dateSearchLogs, setDateSearchLogs] = useState({start:null, end:null})
    const searchLogsTable = e => {
        setPage(0)
        setLogs(logsB.filter(obj => Object.keys(obj).some(field => obj[field].toString().toLowerCase().includes(e.target.value.toLowerCase()))))
        setTextSearchLogs(e.target.value)
    }

    return (
        <div className="bg-bggray dark:bg-darkdark">
            <div className="h-[60px] p-2 inline-flex items-center gap-4 flow-root">
                <div className="float-right inline-flex mt-1 gap-4 items-center mr-1">
                    <div>
{/*                         <input className="focus:outline-none focus:border-b focus:border-bblack mx-2 bg-bggray border-b border-bgray text-textblack" type="date" value={dateSearchLogs.start} onChange={searchLogsTable}/>
                        <input className="focus:outline-none focus:border-b focus:border-bblack mx-2 bg-bggray border-b border-bgray text-textblack" type="date" value={dateSearchLogs.end} onChange={searchLogsTable}/> */}
                        <SearchIcon sx={{color:"#999"}} fontSize="small"/>
                        <input placeholder="Buscar" className="dark:bg-darkdark dark:text-darklightgray focus:outline-none focus:border-b focus:border-bblack mx-2 bg-bggray border-b border-bgray text-textblack" type="text" value={textSearchLogs} onChange={searchLogsTable}/>
                    </div>
                        <CSVLink data={logs} filename={"logs_"+ moment().format("DDMMYY_HHmm") + ".csv"}>
                            <SecondaryButtonCircle>
                                <DownloadIcon className="text-[#333] dark:text-darklightgray" sx={{fontSize:16}}/>
                            </SecondaryButtonCircle> 
                        </CSVLink>
                </div>
            </div>
            <div className={`w-auto ${!isMobile && " overflow-x-scroll"}`}>
                <table className="dark:bg-darkhardgray min-w-[600px] w-full border-collapse table-auto bg-bgwhite">
                    <thead>
                        <tr>
                            <TableTextHeader>FECHA DEL EVENTO<SwapVertIcon onClick={() => sortLogsTable("event_time")} className="float-right hover:text-textblack cursor-pointer" sx={{color:"#999999"}} fontSize="small"/></TableTextHeader>
                            <TableTextHeader>USUARIO<SwapVertIcon onClick={() => sortLogsTable("username")} className="float-right hover:text-textblack cursor-pointer" sx={{color:"#999999"}} fontSize="small"/></TableTextHeader>
                            <TableTextHeader>ROL<SwapVertIcon onClick={() => sortLogsTable("rolename")} className="float-right hover:text-textblack cursor-pointer" sx={{color:"#999999"}} fontSize="small"/></TableTextHeader>
                            <TableTextHeader>MODULO<SwapVertIcon onClick={() => sortLogsTable("module")} className="float-right hover:text-textblack cursor-pointer" sx={{color:"#999999"}} fontSize="small"/></TableTextHeader>
                            <TableTextHeader>EVENTO<SwapVertIcon onClick={() => sortLogsTable("event")} className="float-right hover:text-textblack cursor-pointer" sx={{color:"#999999"}} fontSize="small"/></TableTextHeader>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            logs.map((log, i) => (i >= (page * itemsPerPage) && i < (page * itemsPerPage) + itemsPerPage) && (
                                <tr key={i} className={"odd:bg-bgtablecell dark:bg-darkhardgray dark:odd:bg-darkdark hover:bg-bggray dark:hover:bg-darkblack"}>
                                    <TableTextCell>{moment.utc(log.event_time, "DD/MM/YYYY HH:mm:ss").tz("America/Santiago").local().format("DD [de] MMMM YYYY HH:mm")}</TableTextCell>
                                    <TableTextCell>{log.username}</TableTextCell>
                                    <TableTextCell>{log.rolename}</TableTextCell>
                                    <TableTextCell>{log.module}</TableTextCell>
                                    <TableTextCell>{log.event}</TableTextCell>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>

            <Pagination items={logs.length} page={page} setPage={setPage} itemsPerPage={itemsPerPage}/>
        </div>
    )
}