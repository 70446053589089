import { Title } from '../../../components/Texts.component'
import { CircularGraphicBackups } from '../../../components/Graphics/BackupsCircularStatus.component'
import { CircularGraphicRestorations } from '../../../components/Graphics/RestoreCircularStatus.component'
import { DetailsContentInfo } from './DetailsContentInfo.component'
import { useState } from 'react'
import { useMediaQuery } from '../../../hooks'

export const ContentDetails = ({database = ""}) => {

    const [reload, setReload] = useState(0)

    const isMobile = useMediaQuery('(min-width: 1024px)')

    let timerId 
    window.addEventListener("resize", e => {
        clearTimeout(timerId)
        timerId = setTimeout(function() {
            setReload(reload + 1)
        }, 1000)
    })

    return (
        <>
            <div className="lg:inline-flex w-full gap-6">
                <div className={`${isMobile ? "mb-0" : "mb-5"} w-full inline-flex h-auto bg-bgwhite dark:bg-darkhardgray shadow-normal rounded p-5`}>
                    <div className="w-full">
                        <Title>Resumen último respaldo {database}</Title>
                        <hr className="border-bgray my-4"/>
                        <DetailsContentInfo database={database}/>
                    </div>
                </div>
                <div  key={reload} className="w-full grid sm:inline-flex h-auto bg-bgwhite dark:bg-darkhardgray shadow-normal rounded p-5">
                    <div className="w-full sm:w-3/6">
                        <Title>Respaldos</Title>
                        <hr className="border-bgray my-4"/>
                        <CircularGraphicBackups database={database}/>
                    </div>
                    <div className="w-full sm:w-3/6">
                        <Title>Restauraciones</Title>
                        <hr className="border-bgray my-4"/>
                        <CircularGraphicRestorations database={database}/>
                    </div>
                </div>
            </div>
        </>
    )
}