import { TopBar, SubTopBar, SideBarLeft, Body } from '../../components/Layout'
import { LightTitle } from '../../components/Texts.component'
import { HomeContentBackups } from './components/HomeContentBackups.component'
import { HomeContentRestoration } from './components/HomeContentRestorations.component'
import { HomeContentGraphics } from './components/HomeContentGraphics.component'

const Home = () => {
    
    return (
        <>
            <TopBar/> 
            <SideBarLeft/>
            <SubTopBar>{"Inicio > Resumen"}</SubTopBar>
            <Body>
                <LightTitle>Resumen</LightTitle>
                <HomeContentGraphics/>
                <div className="my-14"></div>
                <LightTitle>Respaldos</LightTitle>
                <HomeContentBackups/>
                <div className="my-14"></div>
                <LightTitle>Restauraciones</LightTitle>
                <HomeContentRestoration/>
            </Body>
        </>
    )
}

export default Home