import axios from 'axios'
import moment from 'moment'
import { loadAbort } from '../utilities/load-abort.utility'
const apiUrl = "https://g7emytzwaa.execute-api.us-east-1.amazonaws.com/dev/"
const backupsUrl = apiUrl + 'backup/'
const restoreUrl = apiUrl + 'restore/'


export const getBackupsList = (database = null) => {
    const controller = loadAbort()
    const config = {
        params: {offset: 0},
        signal: controller.signal
    }
    if (database) config.params.database = database
    return {
        call: axios.get(backupsUrl, config),
        controller
    }
}

export const restoreBackups = ({database, start_date}) => {
    const controller = loadAbort()
    const body = {database: database, date: moment(start_date, "YYYY-MM-DD").format("DDMMYYYY")}
    const config = {
        signal: controller.signal
    }
    return {
        call: axios.post(restoreUrl, body, config),
        controller
    }
}