import axios from 'axios'
import { loadAbort } from '../utilities/load-abort.utility'
const apiUrl = "https://g7emytzwaa.execute-api.us-east-1.amazonaws.com/dev/"
const restorationsUrl = apiUrl + 'restore/'


export const getRestorationsList = (database = null) => {
    const controller = loadAbort()
    const config = {
        params: {offset: 0},
        signal: controller.signal
    }
    if (database) config.params.database = database
    return {
        call: axios.get(restorationsUrl, config),
        controller
    }
}