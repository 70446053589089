import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Home from './pages/Home/Home.page'
import Details from './pages/Details/Details.page'
import Backups from './pages/Backups'
import Restorations from './pages/Restorations'
import Statistics from './pages/Statistics/Statistics.page'
import Users from './pages/Users'
import Roles from './pages/Roles/Roles.page'
import Logs from './pages/Logs'
import { initialToken, PoliciesToken } from './services/token.service'

function App() {

  initialToken()
  const session_token = JSON.parse(sessionStorage.getItem("session_token"))
  if (session_token.id_token != "undefined" && session_token.token_time != "undefined") {
    
    const policies = PoliciesToken(session_token.id_token)

    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home/>} />
          <Route path="/home" element={<Home/>} />
          <Route path="/details/:database" element={ <Details/>} />
          <Route path="/backups" element={ <Backups/>} />
          <Route path="/restorations" element={<Restorations/>} />
          <Route path="/statistics" element={<Statistics/>} />
          {!!policies.find(policy => policy == 'Usuarios') && <Route path="/users" element={<Users/>} />}
          {!!policies.find(policy => policy == 'Roles') && <Route path="/roles" element={<Roles/>} />}
          {!!policies.find(policy => policy == 'Logs') && <Route path="/logs" element={<Logs/>} />}
        </Routes>
      </BrowserRouter>
    );
  } 
}

export default App;
