import { Title, TextBlack, TextWhite, TextGray } from "../../../components/Texts.component"
import { PrimaryButton, SecondaryButton } from "../../../components/Buttons.component"
import { useDispatch, useSelector } from 'react-redux'
import { useFetchLoad, useMediaQuery } from '../../../hooks'
import { addRoles, editRoles } from '../../../services/roles.service'
import { setRolesModal } from '../../../redux/states/rolesModalSlice'
import { useState } from 'react'

export const RolesModal = ({updateData}) => {

    const isMobile = useMediaQuery('(min-width: 1024px)')
    const isTablet = useMediaQuery('(min-width: 620px)')

    // Handler modal
    const { modalDataIsNewRole, roleData } = useSelector(state => state.rolesModal)

    const dispatch = useDispatch()
    const closeModal = () => {
        const data = {
            showModalData: false,
            showModalDeleteConfirm: false,
            modalDataIsNewRole: true,
            roleData: {
                id: "",
                role_name: "",
                description: "",
                policies: [],
                create_date: ""
            }
        }
        dispatch(setRolesModal(data))
    }

    // Handler create and update role
    const [loading, callToFetch] = useFetchLoad()
    const [form, setForm] = useState({...roleData})

    const submit = async () => {
        closeModal()
        console.log(form)
        const response = (modalDataIsNewRole) 
            ? await callToFetch(addRoles(form))
            : await callToFetch(editRoles(roleData.id, form))
        const responseData = await response.data.message
        updateData()
    }

    const setPolicies = e => {
        let newPolicies = [...form.policies]
        const index = newPolicies.indexOf(e.target.name)

        if (e.target.checked && index == -1) newPolicies.push(e.target.name)
        if (!e.target.checked && index > -1) newPolicies.splice(index, 1)
        setForm({...form, policies: newPolicies})
    }

    return (
        <>
            <div onClick={e => closeModal()} className="bg-bgmodal w-full h-screen left-0 top-0 absolute z-40"></div>
            <div className={`${isTablet ? isMobile ? "w-[800px]" : "w-[600px]" : "w-[400px]"} dark:bg-darkhardgray absolute mt-20 translate-x-[-50%] left-2/4 rounded  h-[560px] bg-bgwhite p-5 z-50`}>
                <div>
                    <Title>{modalDataIsNewRole ? "Nuevo Rol" : "Editar Rol"}</Title>
                    <hr className="border-bgray my-4"/>
                </div>

                <form onSubmit={() => submit()}>
                    <div className="mt-10">
                        <TextGray>Nombre del rol</TextGray>
                        <input 
                            className="mb-10 w-full placeholder:text-textdark dark:bg-darkhardgray placeholder:text-[1.1rem] focus:outline-none focus:border-b focus:border-bblue border-b border-bgray" 
                            placeholder="Ingresar nombre" 
                            type="text" 
                            value={form.role_name} 
                            onChange={e => setForm({...form, role_name:e.target.value})}
                            required
                        />
                        <TextGray>Descripción</TextGray>
                        <input 
                            className="mb-10 w-full placeholder:text-textdark dark:bg-darkhardgray placeholder:text-[1.1rem] focus:outline-none focus:border-b focus:border-bblue border-b border-bgray" 
                            placeholder="Ingresa la descripción del rol" 
                            type="text" 
                            value={form.description} 
                            onChange={e => setForm({...form, description:e.target.value})}
                            required
                        />
                        <TextGray>Permisos</TextGray>
                        <div className="flex flex-col gap-2 mt-2">
                            <div className="inline-flex items-center">
                                <input checked={(form.policies.indexOf("Restore") > -1)} name="Restore" onChange={setPolicies} className="w-6 h-6 rounded border border-bgray" type="checkbox"/>
                                <span className="ml-2 text-textdark">Restaurar y desmontar bases de datos</span> 
                            </div>
                            <div className="inline-flex items-center">
                                <input checked={(form.policies.indexOf("Usuarios") > -1)} name="Usuarios" onChange={setPolicies} className="w-6 h-6 rounded border border-bgray" type="checkbox"/>
                                <span className="ml-2 text-textdark">Administrar Usuarios</span> 
                            </div>
                            <div className="inline-flex items-center">
                                <input checked={(form.policies.indexOf("Roles") > -1)} name="Roles" onChange={setPolicies} className="w-6 h-6 rounded border border-bgray" type="checkbox"/>
                                <span className="ml-2 text-textdark">Administrar Roles</span> 
                            </div>
                            <div className="inline-flex items-center">
                                <input checked={(form.policies.indexOf("Logs") > -1)} name="Logs" onChange={setPolicies} className="w-6 h-6 rounded border border-bgray" type="checkbox"/>
                                <span className="ml-2 text-textdark">Visualización Logs de Auditoría</span> 
                            </div>
                            <div className="inline-flex items-center">
                                <input checked={(form.policies.indexOf("Alerts") > -1)} name="Alerts" onChange={setPolicies} className="w-6 h-6 rounded border border-bgray" type="checkbox"/>
                                <span className="ml-2 text-textdark">Recibir notificaciones por email</span> 
                            </div>
                        </div>
                    </div> 

                    <div className="grid justify-items-end mt-10">
                        <div className="inline-flex gap-2">
                            <SecondaryButton onClick={e => closeModal()}>
                                <TextBlack>Cancelar</TextBlack>
                            </SecondaryButton>
                            <PrimaryButton type={"submit"}>
                                <TextWhite>Guardar</TextWhite>
                            </PrimaryButton> 
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}