import { useDispatch, useSelector } from "react-redux"
import { setAppState } from "../redux/states/appStateSlice"
import { setAppPolicies } from "../redux/states/appPoliciesSlice"
import jwt_decode from "jwt-decode"
import { loadAbort } from "../utilities/load-abort.utility"
import axios from "axios"
import moment from "moment"

export const initialToken = () => {

    let session_token = JSON.parse(sessionStorage.getItem("session_token"))
    if (!session_token || session_token == "undefined") {
        const params = new URLSearchParams(window.location.search)
        const idToken = params.getAll('id_token')[0]
        const refreshToken = params.getAll('refresh_token')[0]
        const accessToken = params.getAll('access_token')[0]

        if (!!accessToken && !!idToken && !!refreshToken) {
            const token = {access_token:accessToken, id_token:idToken, refresh_token:refreshToken, token_time: moment()}
            sessionStorage.setItem("session_token", JSON.stringify(token))
            session_token = token
        } else {
            sessionStorage.removeItem("session_token")
            sessionStorage.removeItem("session")
            window.location.href = "https://inacaprestoredbdev.auth.us-east-1.amazoncognito.com/login?client_id=11bgruoba96bd1bkfd0i6aci8k&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+profile&redirect_uri=https://g7emytzwaa.execute-api.us-east-1.amazonaws.com/dev/auth/login"
        }
    }
}

export const PoliciesToken = (id_token) => {
    
    const dispatch = useDispatch()
    const AppState = useSelector(state => state.appState)
    const decoded = jwt_decode(id_token)

    if (AppState.username != decoded.identities[0].userId) {
        dispatch(setAppState({ ...AppState, username: decoded.identities[0].userId, rolename:decoded.rolename }))
    }

    if (decoded.policies != undefined) {
        let policies = JSON.parse(decoded.policies.replace(/'/g, '"'))
        dispatch(setAppPolicies(policies))
        return policies
    } else {
        sessionStorage.removeItem("session_token")
        sessionStorage.removeItem("session")
        window.location.href = "https://inacaprestoredbdev.auth.us-east-1.amazoncognito.com/login?client_id=11bgruoba96bd1bkfd0i6aci8k&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+profile&redirect_uri=https://g7emytzwaa.execute-api.us-east-1.amazonaws.com/dev/auth/login"
    }
}

export const refreshToken = () => {
    const url = "https://inacaprestoredbdev.auth.us-east-1.amazoncognito.com/oauth2/token"
    const controller = loadAbort()
    const body = {
        grant_type: "refresh_token",
        client_id: "11bgruoba96bd1bkfd0i6aci8k",
        client_secret: "1a8pu65pf7f3ohk8lkgqiv00m7stemka1cihlvd34pdv7mnhrd6b",
        refresh_token: JSON.parse(sessionStorage.getItem("session_token")).refresh_token,
    }
    const config = {
        interceptors: false,
        headers: { 
            "Content-Type": "application/x-www-form-urlencoded",
            "Authorization": "none"
        },
        signal: controller.signal
    }
    return {
        call: axios.post(url, body, config),
        controller
    }
}