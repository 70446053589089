import { Title, TextBlack, TextWhite, TextDark } from "../../../components/Texts.component"
import { PrimaryButton, SecondaryButton } from "../../../components/Buttons.component"
import { useDispatch, useSelector } from 'react-redux'
import { deleteRoles } from '../../../services/roles.service'
import { setRolesModal } from '../../../redux/states/rolesModalSlice'

export const RolesModalDelete = ({updateData}) => {

    // Handler modal
    const { roleData } = useSelector(state => state.rolesModal)

    const dispatch = useDispatch()
    const closeModal = () => {
        const data = {
            showModalData: false,
            showModalDeleteConfirm: false,
            modalDataIsNewRole: true,
            roleData: {
                id: "",
                role_name: "",
                description: "",
                create_date: ""
            }
        }
        dispatch(setRolesModal(data))
    }

    // Handler form data and request
    const submit = async () => {
        closeModal()
        const response = await deleteRoles(roleData.id).call
        const responseData = await response.data.message
        console.log(responseData)
        updateData()
    }

    return (
        <>
            <div onClick={e => closeModal()} className="bg-bgmodal w-full h-screen left-0 top-0 absolute z-40"></div>
            <div className="absolute mt-20 translate-x-[-50%] dark:bg-darkhardgray left-2/4 rounded w-[500px] h-[290px] bg-bgwhite p-5 z-50">
                <div>
                    <Title>Eliminar Rol</Title>
                    <hr className="border-bgray my-4"/>
                </div>

                <div className="grid justify-center my-16">
                    <TextDark>¿Está seguro que desea eliminar el siguiente rol?</TextDark>
                    <Title>{roleData.role_name}</Title>
                </div>

                <form onSubmit={() => submit()}>
                    <div className="grid justify-items-end">
                        <div className="inline-flex gap-2">
                            <SecondaryButton onClick={e => closeModal()}>
                                <TextBlack>Cancelar</TextBlack>
                            </SecondaryButton>
                            <PrimaryButton type={"submit"}>
                                <TextWhite>Eliminar</TextWhite>
                            </PrimaryButton> 
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}