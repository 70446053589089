import { TopBar, SubTopBar, SideBarLeft, Body } from '../../components/Layout'
import { LightTitle } from '../../components/Texts.component'
import { ContentGraphics } from './components/ContentGraphics.component'

const Statistics = () => {
    
    return (
        <>
            <TopBar/> 
            <SideBarLeft/>
            <SubTopBar>{"Estadísticas"}</SubTopBar>
            <Body>
                <LightTitle>Monitor de estado</LightTitle>
                <ContentGraphics/>
            </Body>
        </>
    )
}

export default Statistics