import Highcharts from "highcharts/highstock"
import HighchartsReact from "highcharts-react-official"
import { useFetchLoad, useAsync } from '../../hooks'
import { getBackupsSizeGraphics } from "../../services/metrics.service"
import { barGraphicsAdapter } from "../../adapters/metrics.adapter"
import { useState } from "react"

export const BarGraphicBackups = () => {

  const [loading, callToFetch] = useFetchLoad()
  const [chartOptions, setChartOptions] = useState()

  const getApiData = async () => await callToFetch(getBackupsSizeGraphics())
  const adaptBackups = data => setChartOptions({
		chart: {
			type: 'column',
			backgroundColor: 'transparent',
			height: 300,
		},
		accessibility: { enabled: false },
		title: null,
		subtitle: null,
		xAxis: {
			type: 'category'
		},
		yAxis: { title: null },
		legend: { enabled: false },
		plotOptions: {
			series: {
				borderWidth: 0,
				dataLabels: {
					enabled: true,
					format: '{point.y:.1f} GB'
				}
			}
		},
		tooltip: {
			headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
			pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.1f} GB</b><br/>'
		},
		legend: { itemStyle: { color: "#999" } },
		series: [
			{
				name: "Base de datos",
				colorByPoint: true,
				data: barGraphicsAdapter(data)
			}
		]
    })
	useAsync(getApiData, adaptBackups, () => {}, [])

    return (
        <>
            <HighchartsReact highcharts={Highcharts} options={chartOptions} />
        </>
    )
}