import { TopBar, SubTopBar, SideBarLeft, Body } from '../../components/Layout'
import { LightTitle } from '../../components/Texts.component'
import { RestorationsContent } from './components/RestorationsContent.component'

const Restorations = () => {
    
    return (
        <>
            <TopBar/> 
            <SideBarLeft/>
            <SubTopBar>{"Respaldos > Lista de restauraciones"}</SubTopBar>
            <Body>
                <LightTitle>Restauraciones</LightTitle>
                <RestorationsContent/>
            </Body>
        </>
    )
}

export default Restorations