import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    policies: []
}

export const appPoliciesSlice = createSlice({
    name: "appPolicies",
    initialState,
    reducers: {
        setAppPolicies: (state, action) => {
            return state = action.payload
        }
    }
})

export const { setAppPolicies } = appPoliciesSlice.actions
export default appPoliciesSlice.reducer