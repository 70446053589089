import { Title } from '../../../components/Texts.component'
import { SecondaryButtonCircle } from '../../../components/Buttons.component'
import { RestorationsTable } from '../../../components/Tables/RestorationTable.component'
import RefreshIcon from '@mui/icons-material/Refresh'
import { useState } from 'react'

export const HomeContentRestoration = () => {

    const [reloadTable, setReloadTable] = useState(0)

    return (
        <>
            <div className="w-full h-auto bg-bgwhite dark:bg-darkhardgray shadow-normal rounded p-5">
                <div className="flow-root">
                    <div className="float-left pt-2">
                        <Title>Últimas restauraciones</Title>
                    </div>
                    <div className="float-right gap-2 inline-flex">
                        <SecondaryButtonCircle onClick={() => setReloadTable(reloadTable + 1)}>
                            <RefreshIcon className="text-[#333] dark:text-darklightgray" sx={{fontSize:16}}/>  
                        </SecondaryButtonCircle> 
                    </div>
                </div>
                <hr className="border-bgray my-4"/>
                <RestorationsTable key={reloadTable} numberRows={5}/>
            </div>
        </>
    )
}