import { useState } from 'react'
import { useFetchLoad, useAsync, useMediaQuery } from '../../../hooks'
import { TableTextHeader, TableTextCell, TextBlack, TextGray } from '../../../components/Texts.component'
import { SecondaryButtonCircle } from '../../../components/Buttons.component'
import { getUsersList } from '../../../services/users.service'
import { usersAdapter } from '../../../adapters/users.adapter'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import SwapVertIcon from '@mui/icons-material/SwapVert'
import SearchIcon from '@mui/icons-material/Search'
import DownloadIcon from '@mui/icons-material/Download'
import { useDispatch, useSelector } from 'react-redux'
import { setUsersModal } from '../../../redux/states/usersModalSlice'
import { DateFormat } from '../../../utilities/datetimeFormat.utility'
import { Pagination } from '../../../components/Pagination.component'
import { CSVLink } from "react-csv"
import moment from 'moment'

export const UsersTable = () => {

    const isMobile = useMediaQuery()

    // Call users data
    const [loading, callToFetch] = useFetchLoad()
    
    const [users, setUsers] = useState([])
    const [page, setPage] = useState(0)
    const [itemsPerPage, setItemsPerPage] = useState(12)
    const { username } = useSelector(state => state.appState)
    
    const getApiData = async () => await callToFetch(getUsersList())
    const adaptUsers = (data) => {
        setUsers(usersAdapter(data))
        setUsersB(usersAdapter(data))
    }
    useAsync(getApiData, adaptUsers, () => {}, [])
    
    // Users table data functions
    const [orderSort, setOrderSort] = useState(true)
    const sortUsersTable = (column) => {
        (orderSort)
        ? setUsers([...users].sort((a, b) => (a[column] > b[column]) ? 1 : ((b[column] > a[column]) ? -1 : 0)))
        : setUsers([...users].sort((a, b) => (b[column] > a[column]) ? 1 : ((a[column] > b[column]) ? -1 : 0)))
        setOrderSort(!orderSort)
    }
    
    const [usersB, setUsersB] = useState([])
    const [textSearchUsers, setTextSearchUsers] = useState("")
    const searchUsersTable = e => {
        setPage(0)
        setUsers(usersB.filter(obj => Object.keys(obj).some(field => obj[field].toString().toLowerCase().includes(e.target.value.toLowerCase()))))
        setTextSearchUsers(e.target.value)
    }

    // Handler modal
    const dispatch = useDispatch()
    const openModal = (user) => {
        const data = {
            showModalData: true,
            showModalDeleteConfirm: false,
            modalDataIsNewUser: false,
            userData: {
                id: user.id,
                username: user.username,
                email: user.email,
                role_id: user.role_id,
                role_name: user.role_name,
                create_date: user.create_date
            }
        }
        dispatch(setUsersModal(data))
    }

    const deleteUser = async (user) => {
        const data = {
            showModalData: false,
            showModalDeleteConfirm: true,
            modalDataIsNewUser: false,
            userData: {
                id: user.id,
                username: user.username,
                email: user.email,
                role_id: user.role_id,
                role_name: user.role_name,
                create_date: user.create_date
            }
        }
        dispatch(setUsersModal(data))
    }

    return (
        <div className="bg-bggray dark:bg-darkdark">
            <div className="h-[60px] p-2 inline-flex items-center gap-4 flow-root">
                <div className="float-right inline-flex mt-1 gap-4 items-center mr-1">
                    <div>
                        <SearchIcon sx={{color:"#999"}} fontSize="medium"/>
                        <input placeholder="Buscar..." className={`focus:outline-none focus:border-b focus:border-bblack mx-2 dark:bg-darkdark bg-bggray border-b border-bgray dark:text-darklightgray text-textblack`} type="text" value={textSearchUsers} onChange={searchUsersTable}/>
                    </div>
                        <CSVLink data={users} filename={"users_"+ moment().format("DDMMYY_HHmm") + ".csv"}>
                            <SecondaryButtonCircle>
                                <DownloadIcon className="text-[#333] dark:text-darklightgray" sx={{fontSize:16}}/>
                            </SecondaryButtonCircle> 
                        </CSVLink>
                </div>
            </div>
            <div className={`w-auto ${!isMobile && " overflow-x-scroll"}`}>
                <table className="min-w-[600px] dark:bg-darkhardgray w-full border-collapse table-auto bg-bgwhite">
                    <thead>
                        <tr>
                            <TableTextHeader>FECHA DE CREACIÓN<SwapVertIcon onClick={() => sortUsersTable("create_date")} className="float-right hover:text-textblack cursor-pointer" sx={{color:"#999999"}} fontSize="small"/></TableTextHeader>
                            <TableTextHeader>NOMBRE DE USUARIO<SwapVertIcon onClick={() => sortUsersTable("username")} className="float-right hover:text-textblack cursor-pointer" sx={{color:"#999999"}} fontSize="small"/></TableTextHeader>
                            <TableTextHeader>EMAIL<SwapVertIcon onClick={() => sortUsersTable("email")} className="float-right hover:text-textblack cursor-pointer" sx={{color:"#999999"}} fontSize="small"/></TableTextHeader>
                            <TableTextHeader>ROL<SwapVertIcon onClick={() => sortUsersTable("role_name")} className="float-right hover:text-textblack cursor-pointer" sx={{color:"#999999"}} fontSize="small"/></TableTextHeader>
                            <TableTextHeader>ACCIONES</TableTextHeader>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            users.map((user, i) => (i >= (page * itemsPerPage) && i < (page * itemsPerPage) + itemsPerPage) && (
                                <tr key={i} className={"odd:bg-bgtablecell dark:bg-darkhardgray dark:odd:bg-darkdark hover:bg-bggray dark:hover:bg-darkblack"}>
                                    <TableTextCell>{moment(user.create_date, "YYYY-MM-DD").format("DD [de] MMMM YYYY")}</TableTextCell>
                                    <TableTextCell>{user.username}</TableTextCell>
                                    <TableTextCell>{user.email}</TableTextCell>
                                    <TableTextCell>{user.role_name}</TableTextCell>
                                    <td className="border dark:border-darktableborder border-bgray px-2 text-center">
                                        { username != user.email 
                                            ?<div className="flex gap-2 mx-auto justify-center">
                                                <div onClick={() => openModal(user)} className="inline-flex cursor-pointer">
                                                    <EditIcon sx={{color:"#666"}} fontSize="small"/>
                                                    {isMobile && <TextBlack>Editar</TextBlack>}
                                                </div>
                                                <div onClick={() => deleteUser(user)} className="inline-flex cursor-pointer">
                                                    <DeleteIcon sx={{color:"#666"}} fontSize="small"/>
                                                    {isMobile && <TextBlack>Eliminar</TextBlack>}
                                                </div>
                                            </div>
                                            : <div className="flex gap-2 mx-auto justify-center">
                                            <div className="inline-flex cursor-not-allowed">
                                                <EditIcon sx={{color:"#ccc"}} fontSize="small"/>
                                                {isMobile && <TextGray>Editar</TextGray>}
                                            </div>
                                            <div className="inline-flex cursor-not-allowed">
                                                <DeleteIcon sx={{color:"#ccc"}} fontSize="small"/>
                                                {isMobile && <TextGray>Eliminar</TextGray>}
                                            </div>
                                        </div>
                                        }
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
            <Pagination items={users.length} page={page} setPage={setPage} itemsPerPage={itemsPerPage}/>
        </div>
    )
}