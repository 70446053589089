import { Title } from '../../../components/Texts.component'
import { CircularGraphicBackups } from '../../../components/Graphics/BackupsCircularStatus.component'
import { CircularGraphicRestorations } from '../../../components/Graphics/RestoreCircularStatus.component'
import { BarGraphicBackups } from '../../../components/Graphics/BackupsBarSize.component'
import { LinearGraphicBackups } from '../../../components/Graphics/BackupsLinearSize.component'
import { useState } from 'react'
import { StorageSize } from '../../../components/Graphics/StorageSize.component'

export const ContentGraphics = () => {

    const [reload, setReload] = useState(0)
    let timerId 

    window.addEventListener("resize", e => {
        clearTimeout(timerId)
        timerId = setTimeout(function() {
            setReload(reload + 1)
        }, 1000)
    })

    return (
        <>
            <div key={reload}>
                <div className="grid lg:inline-flex w-full gap-6">
                    <div className="w-full grid sm:inline-flex h-auto bg-bgwhite dark:bg-darkhardgray shadow-normal rounded p-5">
                        <div className="w-full sm:w-3/6">
                            <Title>Respaldos</Title>
                            <hr className="border-bgray my-4"/>
                            <CircularGraphicBackups/>
                        </div>
                        <div className="w-full sm:w-3/6">
                            <Title>Restauraciones</Title>
                            <hr className="border-bgray my-4"/>
                            <CircularGraphicRestorations/>
                        </div>
                    </div>
                    <div className="w-full inline-flex h-auto bg-bgwhite dark:bg-darkhardgray shadow-normal rounded p-5">
                        <div className="w-full">
                            <Title>Espacio en disco último respaldo (GB)</Title>
                            <hr className="border-bgray my-4"/>
                            <BarGraphicBackups/>
                        </div>
                    </div>
                </div>
                <div className="mt-6 grid lg:inline-flex w-full gap-6">
                    <div className="w-full grid sm:inline-flex h-auto bg-bgwhite dark:bg-darkhardgray shadow-normal rounded p-5">
                        <div className="w-full">
                            <Title>Evolutivo de espacio en disco por Base de datos (GB)</Title>
                            <hr className="border-bgray my-4"/>
                            <LinearGraphicBackups/>
                        </div>
                    </div>
                    <div className="w-full inline-flex h-auto bg-bgwhite dark:bg-darkhardgray shadow-normal rounded p-5">
                        <div className="w-full">
                            <Title>Estado Storage</Title>
                            <hr className="border-bgray my-4"/>
                            <StorageSize/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}