export const logsAdapter = (logs) => {
    let formattedLogs = []

    for (let log of logs.data) {
        formattedLogs.push({
            event_time: log.EventTime ||"",
            module: log.Module,
            event: log.Event || "",
            rolename: log.RoleName || "",
            username: log.UserId || "",
        })
    }

    return formattedLogs
}