import { TopBar, SubTopBar, SideBarLeft, Body } from '../../components/Layout'
import { LightTitle } from '../../components/Texts.component'
import { RolesContent } from './components/RolesContent.component'
import { RolesModal } from './components/RolesModal.component'
import { RolesModalDelete } from './components/RolesModalDelete.component'
import { useSelector } from 'react-redux'
import { useState } from 'react'

const Roles = () => {
    
    const { showModalData, showModalDeleteConfirm } = useSelector(state => state.rolesModal)
    const [loadTable, setLoadTable] = useState(0)
    const updateData = () => setLoadTable(loadTable + 1)

    return (
        <>
            {showModalData && <RolesModal updateData={updateData}/>}
            {showModalDeleteConfirm && <RolesModalDelete updateData={updateData}/>}
            <TopBar/> 
            <SideBarLeft/>
            <SubTopBar>{"Administración > Roles"}</SubTopBar>
            <Body>
                <LightTitle>Roles</LightTitle>
                <RolesContent key={loadTable}/>
            </Body>
        </>
    )
}

export default Roles