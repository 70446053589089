export const rolesAdapter = (roles) => {
    let formattedRoles = []

    for (let rol of roles.data) {
        formattedRoles.push({
            id: rol.id || 0,
            role_name: rol.role_name || "",
            description: rol.description || "",
            policies: rol.policies || "",
            created_at: rol.create_date || ""
        })
    }

    return formattedRoles
}