import { TopBar, SubTopBar, SideBarLeft, Body } from '../../components/Layout'
import { LightTitle } from '../../components/Texts.component'
import { UsersContent } from './components/UsersContent.component'
import { UsersModal } from './components/UsersModal.component'
import { UsersModalDelete } from './components/UsersModalDelete.component'
import { useSelector } from 'react-redux'
import { useState } from 'react'

const Users = () => {
    
    const { showModalData, showModalDeleteConfirm } = useSelector(state => state.usersModal)
    const [loadTable, setLoadTable] = useState(0)
    const updateData = () => setLoadTable(loadTable + 1)

    return (
        <>
            {showModalData && <UsersModal updateData={updateData}/>}
            {showModalDeleteConfirm && <UsersModalDelete updateData={updateData}/>}
            <TopBar/> 
            <SideBarLeft/>
            <SubTopBar>{"Administración > Usuarios"}</SubTopBar>
            <Body>
                <LightTitle>Usuarios</LightTitle>
                <UsersContent key={loadTable}/>
            </Body>
        </>
    )
}

export default Users