import { getStorageSize } from "../../services/metrics.service"
import { storageSizeAdapter } from "../../adapters/metrics.adapter"
import { useFetchLoad, useAsync } from '../../hooks'
import { useState } from "react"

export const StorageSize = () => {

    const [loading, callToFetch] = useFetchLoad()
    const [storageSize, setStorageSize] = useState({size: 0, health: "..."})
  
    const getApiData = async () => await callToFetch(getStorageSize())
    const adaptBackups = (data) => setStorageSize(storageSizeAdapter(data))
    useAsync(getApiData, adaptBackups, () => {}, [])

    return (
        <>
            <div className="flex gap-10 w-100 justify-center">
                <div className="block">
                    <div className="text-[2rem] text-textblack w-full text-center dark:text-darklightgray">{storageSize?.size_s3 != undefined ? storageSize?.size_s3 + "TB" : "--"}</div>
                    <div className="text-[1rem] text-textgray w-full text-center">Standard</div>
                </div>
                <div className="block">
                    <div className="text-[2rem] text-textblack w-full text-center dark:text-darklightgray">{storageSize?.size_glacier != undefined ? storageSize?.size_glacier + "TB" : "--"}</div>
                    <div className="text-[1rem] text-textgray w-full text-center">Glacier</div>
                </div>
            </div>
            <div className="text-[.8rem] text-textgray w-full text-center">Espacio en disco utilizado en Amazon S3</div>
            <div className={`${storageSize?.health == "RUNNING" ? "text-[#00D481]" : "text-[#FF5151]"} mt-4 text-[2rem] w-full text-center`}>{storageSize?.health}</div>
            <div className="text-[1rem] text-textgray w-full text-center">Status Storage Gateway</div>
        </>
    )
}