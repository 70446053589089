import moment from 'moment'

export const restorationsAdapter = (restorations) => {
    
    let formattedRestorations = []
    
    for (let restoration of restorations.data) {
        formattedRestorations.push({
            database: restoration.Database || "",
            restoration_date: restoration.RestorationDate || "",
            percentage: restoration.Percentage || "",
            last_date: restoration.LastStatusUpdate || "",
            backup: restoration.Database + "-" + restoration.Backup || "",
            start_date: restoration.Date || "",
            status: restoration.ActualStatus || "",
            prev_status: restoration.PreviousStatus || "",
            detail_status: restoration.StatusDetail || ""
        })
    }

    return [restorations.item_count, formattedRestorations]
}

export const restorationUpdateAdapter = (newData, restorations) => {

    let formattedRestorations = []
    for (let restoration of restorations) {
        const newRestore = newData.data.find(el => String(el.Database + "-" + el.Backup + el.Date) == restoration.backup + restoration.start_date)
        formattedRestorations.push({
            database: newRestore.Database || "",
            restoration_date: newRestore.RestorationDate || "",
            percentage: newRestore.Percentage || "",
            last_date: newRestore.LastStatusUpdate || "",
            backup: newRestore.Database + "-" + newRestore.Backup || "",
            start_date: newRestore.Date || "",
            status: newRestore.ActualStatus || "",
            prev_status: newRestore.PreviousStatus || "",
            detail_status: newRestore.StatusDetail || ""
        })
    }

    return [newData.item_count, formattedRestorations]
}