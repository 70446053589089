import { Link } from 'react-router-dom'
import { Title, TextBlackFixed } from '../Texts.component'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import { useDispatch, useSelector } from 'react-redux'
import { setAppState } from '../../redux/states/appStateSlice'
import { useEffect } from 'react'
import { useMediaQuery } from '../../hooks'

export const SideBarLeft = () => {

    const dispatch = useDispatch()
    const AppState = useSelector(state => state.appState)
    const policies = useSelector(state => state.appPolicies)

    let menu_items = [
        {
            name:"home",
            label: 'Inicio',
            link: '/home',
            submenu: null,
            policy: true,
        },
        {
            name:"backups",
            label: 'Respaldos',
            link: null,
            policy: true,
            submenu: [
                {
                    label: 'Historial de Respaldos',
                    link: '/backups',
                    policy: true,
                },
                {
                    label: 'Historial de Restauraciones',
                    link: '/restorations',
                    policy: true,
                }
            ]
        },
        {
            name:"statistics",
            label: 'Estadísticas',
            link: '/statistics',
            submenu: null,
            policy: true,
        },
        {
            name:"administration",
            label: 'Administración',
            link: null,
            policy: false,
            submenu: [
                {
                    label: 'Usuarios',
                    link: '/users',
                    policy: false
                },
                {
                    label: 'Roles',
                    link: '/roles',
                    policy: false
                },
                {
                    label: 'Logs',
                    link: '/logs',
                    policy: false
                }
            ]
        }
    ]

    if (!!policies.find(policy => policy == 'Usuarios' || policy == 'Roles' || policy == 'Logs')) menu_items[3].policy = true
    if (!!policies.find(policy => policy == 'Usuarios')) menu_items[3].submenu[0].policy = true
    if (!!policies.find(policy => policy == 'Roles')) menu_items[3].submenu[1].policy = true
    if (!!policies.find(policy => policy == 'Logs')) menu_items[3].submenu[2].policy = true

    const isMobile = useMediaQuery('(min-width: 1024px)')
    const isSmallMobile = useMediaQuery('(min-width: 550px)')

    useEffect(() => {
        (JSON.parse(sessionStorage.getItem("session")) == undefined) 
        ? sessionStorage.setItem("session", JSON.stringify(AppState))
        : dispatch(setAppState(JSON.parse(sessionStorage.getItem("session"))))
    }, [])
    
    const toggleMenu = () => {
        let newAppState
        newAppState = {...AppState, open_menu: !AppState.open_menu}
        dispatch(setAppState(newAppState))
        sessionStorage.setItem("session", JSON.stringify(newAppState))
    }

    const toggleSubmenu = menuItem => {
        let newAppState
        if (menuItem == "backups") newAppState = {...AppState, open_submenu_backups: !AppState.open_submenu_backups}
        if (menuItem == "administration") newAppState = {...AppState, open_submenu_admin: !AppState.open_submenu_admin}
        dispatch(setAppState(newAppState))
        sessionStorage.setItem("session", JSON.stringify(newAppState))
    }

    return (
        <div>
            <div className={`flex flex-col w-[260px] dark:bg-darkhardgray bg-bgwhite h-screen ${(isMobile || AppState.open_menu ) ? "ml-0" : (!isSmallMobile) ? "ml-[-240px]" : "ml-[-220px]"} transition-all ease duration-200 shadow-normal pt-7 mt-10 fixed z-20`}>
                {(isMobile || AppState.open_menu ) && menu_items.map(item => item.policy && (
                    <div key={item.name} >
                        <div className="w-100 h-[50px] px-4 pt-3 border-b dark:border-darklightgray border-bdark flow-root">
                            <div className="float-left">
                                <Link to={item.link} replace>
                                    <Title>{item.label}</Title>
                                </Link>
                            </div>
                            {item.link == null && 
                                <div className="float-right cursor-pointer" onClick={() => toggleSubmenu(item.name)}>
                                    <KeyboardArrowDownIcon className="dark:text-darklightgray text-[#333]" fontSize="small"/>
                                </div>
                            }
                        </div>
                        {item.submenu != null && (
                            <div className={`${(item.name == "backups" && AppState.open_submenu_backups || item.name == "administration" && AppState.open_submenu_admin) ? "py-3" : "py-0" } pl-5 dark:bg-bgblack2 bg-bggray transition-all ease duration-200`}>
                                {item.submenu.map(sub_menu => sub_menu.policy && (
                                    <div 
                                        key={sub_menu.label} 
                                        className={`${(item.name == "backups" && AppState.open_submenu_backups || item.name == "administration" && AppState.open_submenu_admin) ? "h-[25px] w-100 pl-4" : "hidden" } transition-all ease duration-200`}
                                    >
                                        <Link to={sub_menu.link} replace>
                                            <TextBlackFixed>{sub_menu.label}</TextBlackFixed>
                                        </Link>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                ))}
            </div>
            {!isMobile && <div onClick={toggleMenu} className={`${(isMobile || AppState.open_menu ) ? "ml-[240px] hover:ml-[235px]" : (!isSmallMobile) ? "ml-[5px] hover:ml-[10px]" : "ml-[20px] hover:ml-[25px]"} transition-all ease duration-200 w-[40px] h-[40px] items-center shadow-normal  flex border-bblack justify-center mt-[70px] rounded-[20px] fixed bg-bgwhite z-20 cursor-pointer`}>
                {(AppState.open_menu) 
                ? <KeyboardArrowLeftIcon sx={{color:"#333333"}} fontSize="medium"/>
                : <KeyboardArrowRightIcon sx={{color:"#333333"}} fontSize="medium"/> 
                }
            </div>}
        </div>
    )
}