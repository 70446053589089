import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    showModalData: false,
    modalDataIsNewUser: true,
    showModalDeleteConfirm: false,
    create: false,
    update: false,
    delete: false,
    userData: {
        id: "",
        username: "",
        email: "",
        role_id: "",
        role_name: "",
        create_date: ""
    }
}

export const usersModalSlice = createSlice({
    name: "usersModal",
    initialState,
    reducers: {
        setUsersModal: (state, action) => {
            return state = action.payload
        }
    }
})

export const { setUsersModal } = usersModalSlice.actions
export default usersModalSlice.reducer