
export const Pagination = ({items, page, setPage, itemsPerPage}) => {

    const showPagination = items > itemsPerPage
    let numPages = (items % itemsPerPage > 0) ? (items / itemsPerPage) + 1 : items / itemsPerPage

    let pageList = []
    let init = (page <= 1) ? 1 : page - 1
    pageList.push("<<")
    pageList.push("<")
    if (init + 2 == Math.floor(numPages) && init > 4) {
        pageList.push((Math.floor(numPages) - 4))
        pageList.push((Math.floor(numPages) - 3))
    }
    if (init + 3 == Math.floor(numPages) && init > 4) {
        pageList.push((Math.floor(numPages) - 4))
    }
    for (let i = init; i <= init + 4; i++) {
        if (i <= Math.floor(numPages)) pageList.push(i)
    }
    pageList.push(">")
    pageList.push(">>")

    const onClickNumber = (number) => {
        if (typeof number == "number") setPage(number - 1)
        if (number == "<<") setPage(0)
        if (number == "<" && page > 0) setPage(page - 1)
        if (number == ">>") setPage(Math.floor(numPages) - 1)
        if (number == ">" && page < (numPages - 2)) setPage(page + 1)
    }

    return (
        <>
            {showPagination && <div className="dark:bg-darkhardgray bg-bgwhite h-[40px] w-full grid justify-end items-center pt-4">
                <div className="inline-flex">
                    {pageList.map(number => (
                        <div 
                            className={`${((page + 1) == number) ? "bg-bgred text-darkwhite" : "text-textdark dark:text-darkwhite" }  cursor-pointer rounded-[16px]  flex items-center justify-center w-[20px]  text-[13px] mx-[2px]`}
                            key={number}
                            onClick={() => onClickNumber(number)}
                        >
                            {number}
                        </div>
                    ))}
                </div>
            </div>}
        </>
    )
}