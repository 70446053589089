import axios from 'axios'
import { loadAbort } from '../utilities/load-abort.utility'
const apiUrl = "https://g7emytzwaa.execute-api.us-east-1.amazonaws.com/dev/auth/"
const logout = apiUrl + 'logout/'


export const logoutSession = () => {
    const controller = loadAbort()
    const config = {
        signal: controller.signal
    }
    return {
        call: axios.get(logout, config),
        controller
    }
}