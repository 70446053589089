import { useEffect, useState } from "react";

export const useFetchLoad = () => {

    const [loading, setLoading] = useState(false)

    let controller 

    const callToFetch = async (request) => {
        if (request.controller) controller = request.controller
        setLoading(true)
        let result = {}
        try { 
            result = await request.call 
        } catch (err) {
            setLoading(false)
            throw err
        }
        setLoading(false)
        return result
    }
    
    const cancelToFetch = () => {
        setLoading(false)
        controller && controller.abort()
    }

    useEffect(() => {
        return () => {
            cancelToFetch()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return [loading, callToFetch]
}