export const usersAdapter = (users) => {
    let formattedUsers = []

    for (let user of users.data) {
        formattedUsers.push({
            id: user.id || 0,
            username: user.username || "",
            email: user.email || "",
            role_name: user.role_name || "",
            role_id: user.role_id || "",
            create_date: user.create_date || ""
        })
    }

    return formattedUsers
}