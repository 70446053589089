import { Title, TextBlack, TextWhite, TextDark } from "../../../components/Texts.component"
import { PrimaryButton, SecondaryButton } from "../../../components/Buttons.component"
import { useDispatch, useSelector } from 'react-redux'
import { deleteUsers } from '../../../services/users.service'
import { setUsersModal } from '../../../redux/states/usersModalSlice'

export const UsersModalDelete = ({updateData}) => {

    // Handler modal
    const { userData } = useSelector(state => state.usersModal)

    const dispatch = useDispatch()
    const closeModal = () => {
        const data = {
            showModalData: false,
            showModalDeleteConfirm: false,
            modalDataIsNewUser: true,
            create: false,
            update: false,
            delete: false,
            userData: {
                id: "",
                username: "",
                email: "",
                role_id: "",
                role_name: "",
                create_date: ""
            }
        }
        dispatch(setUsersModal(data))
    }

    // Handler form data and request
    const submit = async () => {
        closeModal()
        const response = await deleteUsers(userData.id).call
        const responseData = await response.data.message
        console.log(responseData)
        updateData()
    }

    return (
        <>
            <div onClick={e => closeModal()} className="bg-bgmodal w-full h-screen left-0 top-0 absolute z-40"></div>
            <div className="absolute mt-20 translate-x-[-50%] left-2/4 rounded w-[500px] h-[290px] dark:bg-darkhardgray bg-bgwhite p-5 z-50">
                <div>
                    <Title>Eliminar Usuario</Title>
                    <hr className="border-bgray my-4"/>
                </div>

                <div className="grid justify-center my-16">
                    <TextDark>¿Está seguro que desea eliminar el siguiente usuario?</TextDark>
                    <Title>{userData.username}</Title>
                </div>

                <form onSubmit={() => submit()}>
                    <div className="grid justify-items-end">
                        <div className="inline-flex gap-2">
                            <SecondaryButton onClick={e => closeModal()}>
                                <TextBlack>Cancelar</TextBlack>
                            </SecondaryButton>
                            <PrimaryButton type={"submit"}>
                                <TextWhite>Eliminar</TextWhite>
                            </PrimaryButton> 
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}