import { TopBar, SubTopBar, SideBarLeft, Body } from '../../components/Layout'
import { LightTitle } from '../../components/Texts.component'
import { BackupsContent } from './components/BackupsContent.component'

const Backups = () => {
    
    return (
        <>
            <TopBar/> 
            <SideBarLeft/>
            <SubTopBar>{"Respaldos > Lista de respaldos"}</SubTopBar>
            <Body>
                <LightTitle>Respaldos</LightTitle>
                <BackupsContent/>
            </Body>
        </>
    )
}

export default Backups