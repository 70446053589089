import { Title, TextWhite } from '../../../components/Texts.component'
import { PrimaryButtonCircle, SecondaryButtonCircle } from '../../../components/Buttons.component'
import { RolesTable } from './RolesTable.component'
import PersonAddAlt from '@mui/icons-material/PersonAddAlt'
import RefreshIcon from '@mui/icons-material/Refresh'
import { useDispatch } from 'react-redux'
import { useState } from 'react'
import { setRolesModal } from '../../../redux/states/rolesModalSlice'
import { useMediaQuery } from '../../../hooks'

export const RolesContent = () => {

    const isMobile = useMediaQuery()

    const dispatch = useDispatch()
    const openModal = () => {
        const data = {
            showModalData: true,
            showModalDeleteConfirm: false,
            modalDataIsNewRole: true,
            roleData: {
                id: "",
                role_name: "",
                description: "",
                policies: [],
                create_date: ""
            }
        }
        dispatch(setRolesModal(data))
    }

    const [reloadTable, setReloadTable] = useState(0)

    return (
        <>
            <div className="w-full h-auto dark:bg-darkhardgray bg-bgwhite shadow-normal rounded p-5">
                <div className="flow-root">
                    <div className="float-left pt-2">
                        <Title>Lista de roles</Title>
                    </div>
                    <div className="float-right gap-2 inline-flex">
                        <PrimaryButtonCircle onClick={openModal}>
                            <PersonAddAlt className="dark:text-darkblack text-darklightgray" fontSize="small"/>
                            {isMobile && <TextWhite>Nuevo Rol</TextWhite>}
                        </PrimaryButtonCircle>
                        <SecondaryButtonCircle onClick={() => setReloadTable(reloadTable + 1)}>
                            <RefreshIcon className="text-[#333] dark:text-darklightgray" sx={{fontSize:16}}/> 
                        </SecondaryButtonCircle> 
                    </div>
                </div>
                <hr className="border-bgray my-4"/>
                <RolesTable key={reloadTable}/>
            </div>
        </>
    )
}