import axios from 'axios'
import { loadAbort } from '../utilities/load-abort.utility'
const apiUrl = "https://g7emytzwaa.execute-api.us-east-1.amazonaws.com/dev/auth/"
const userUrl = apiUrl + 'users/'


export const getUsersList = () => {
    const controller = loadAbort()
    const config = {
        params: {offset: 0},
        signal: controller.signal
    }
    return {
        call: axios.get(userUrl, config),
        controller
    }
}

export const addUsers = (body) => {
    const controller = loadAbort()
    const config = {
        signal: controller.signal
    }
    return {
        call: axios.post(userUrl, body, config),
        controller
    }
}

export const editUsers = (user_id, {username, email, role_id}) => {
    const controller = loadAbort()
    const body = { username, email, role_id }
    const config = {
        signal: controller.signal
    }
    return {
        call: axios.put(userUrl + user_id, body, config),
        controller
    }
}

export const deleteUsers = (user_id) => {
    const controller = loadAbort()
    const config = {
        signal: controller.signal
    }
    return {
        call: axios.delete(userUrl + user_id, config),
        controller
    }
}