import { useEffect } from "react";

export const useAsync = (asyncFn, succesFn, returnFn, restrictions) => {

    useEffect(() => {
        let isActive = true
        asyncFn().then((result) => {
            if (isActive) succesFn(result.data)
        })
        return () => {
            returnFn && returnFn()
            isActive = false
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, restrictions)
}