import moment from "moment"

export const backupsAdapter = (backups) => {
    
    let formattedBackups = []
    
    for (let backup of backups.data.items) {
        formattedBackups.push({
            id: backup.BackupId || "",
            size: Number((backup.Size / 1073741824 ).toFixed(2))|| 0,
            database: backup.Database || "",
            start_date: backup.StartDate || "",
            status: backup.Status || ""
        })
    }

    return [backups.item_count, formattedBackups]
}

export const lastBackupsAdapter = (backups) => {
    
    let formattedLastBackups = backups.data.items.sort((a, b) => b.StartDate > a.StartDate)[0]
    formattedLastBackups.Checksum = backups.data.last_backup.checksums || ""
    return formattedLastBackups
}