import { TextDark, TextGray, TitleTopBar, TextTopBar, TextBlack, TextDarkStrong } from "../Texts.component"
import { TopBarButton } from "../Buttons.component"
import logoMain from '../../assets/logoMain.png'
import logoWhite from '../../assets/logoWhite.png'
import { useFetchLoad, useMediaQuery } from '../../hooks'
import { useSelector, useDispatch } from "react-redux"
import { useEffect, useState } from "react"
import { setAppState } from '../../redux/states/appStateSlice'
import { logoutSession } from "../../services/session.service"
//Icons
import AccessibilityIcon from '@mui/icons-material/Accessibility'
import OutputIcon from '@mui/icons-material/Output'
import Brightness6Icon from '@mui/icons-material/Brightness6'
import moment from "moment"
import { refreshToken } from "../../services/token.service"


export const TopBar = () => {

    const dispatch = useDispatch()
    const [loading, callToFetch] = useFetchLoad()
    const isMobile = useMediaQuery('(min-width: 600px)')
    const isSmallMobile = useMediaQuery('(min-width: 550px)')
    const AppState = useSelector(state => state.appState)
    const { username } = useSelector(state => state.appState)
    const [showOptions, setShowOptions] = useState(false)
    

    /**
     * Theme handler
     */
    useEffect(() => {
        if (AppState.theme == 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
            document.documentElement.classList.add('dark')
        } else {
            document.documentElement.classList.remove('dark')
        }
    }, [AppState])
    
    const toggleTheme = () => {
        dispatch(setAppState({...AppState, theme: AppState.theme == "light" ? "dark" : "light"}))
        sessionStorage.setItem("session", JSON.stringify({...AppState, theme: AppState.theme == "light" ? "dark" : "light"}))
        const session = JSON.parse(sessionStorage.getItem("session"))
        if (session.theme === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
            document.documentElement.classList.add('dark')
        } else {
            document.documentElement.classList.remove('dark')
        }
    }

    
    /**
     * logout handler
     */
    const [idleTime, setIdleTime] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setIdleTime(prevIdleTime => prevIdleTime + 1)
        }, 60000)
        return () => clearInterval(intervalId)
    }, [])
  
    useEffect(() => {
        if (idleTime >= 15) logout()
    }, [idleTime])


    useEffect(() => {
        document.addEventListener("mousemove", handleUserActivity)
        return () => document.removeEventListener("mousemove", handleUserActivity)
    }, [])

    const handleUserActivity = () => {
        setIdleTime(0)
    }
        
    const logout = () => {
        const logoutApp = async () => await callToFetch(logoutSession())
        logoutApp().then(res => {
            window.location.href = "https://inacaprestoredbdev.auth.us-east-1.amazoncognito.com/logout?client_id=11bgruoba96bd1bkfd0i6aci8k&response_type=token&scope=aws.cognito.signin.user.admin+email+openid+profile&logout_uri=https://d2ypop5qbt8zu6.cloudfront.net"
            sessionStorage.removeItem("session_token")
            sessionStorage.removeItem("session")
        })
    }


    /**
     * Refresh token handler
     */
    useEffect(() => {
        const { token_time } = JSON.parse(sessionStorage.getItem("session_token"))
        if (!!token_time && moment(token_time).add(5, "minutes") < moment()) onRefreshToken()
    }, [idleTime])

    const onRefreshToken = () => {
        const getApiData = async () => await callToFetch(refreshToken())
        getApiData().then(res => {
            let session_token = JSON.parse(sessionStorage.getItem("session_token"))
            sessionStorage.setItem("session_token", JSON.stringify({
                ...session_token, 
                id_token:res.data.id_token, 
                access_token: res.data.access_token, 
                token_time: moment()
            }))
        }).catch(err => {
            logout()
        })
    }

    return (
        <div className="flex dark:bg-darkdark justify-center w-full bg-bggray h-[48px] border-t-[6px] dark:border-darkwhite border-bred shadow-normal fixed z-30">
            <div className="flow-root w-[1415px] ">
                <div className="inline-flex float-left h-[48px] ml-[10px]">
                    <div className={`${isSmallMobile ? "w-[24px] ml-[6px]" : "w-[16px] ml-[0px]"} dark:bg-darkdark bg-bgwhite absolute mt-[-6px] h-[6px]`}></div>
                    <img className={`${isSmallMobile ? "w-[110px] h-[32px] mt-[5px] ml-[5px]" : "w-[80px] h-[25px] mt-[10px]"}`} src={AppState.theme == "light" ? logoMain : logoWhite} alt="logo Inacap"/>
                    <div className={`${isSmallMobile ? "mx-2" : "mx-1"} mt-[5px] h-[30px] w-[6px] border-l-2 border-bgray`}></div>
                    <div className="flex items-center mb-1">
                        <TitleTopBar>Administración y gestión de respaldos</TitleTopBar>
                    </div>
                </div>
                <div className={`${isSmallMobile ? "mr-[10px]" : "re-[0px]"} inline-flex float-right h-[48px]`}>
                    <div className="flex items-center mb-1 gap-4 mx-2">
                        {isMobile && <TextTopBar>{username}</TextTopBar>}
                        <div className="flex gap-2">
                            <TopBarButton onClick={() => setShowOptions(!showOptions)}>
                                <AccessibilityIcon className="dark:text-darklightgray text-[#333]" sx={{fontSize: 18}}/>
                            </TopBarButton>
                            {showOptions &&
                                <div className="fixed mt-10">
                                    <div className="px-4 pt-1 pb-2 ml-[-140px] border border-bgray rounded dark:bg-darkhardgray dark:border-darkmediumgray bg-bggray text-sm w-[180px] items-center">
                                        <TextDarkStrong>ACCESIBILIDAD</TextDarkStrong>
                                        <div onClick={toggleTheme} className="inline-flex cursor-pointer items-center">
                                            <TextBlack>Modo oscuro</TextBlack>
                                            <Brightness6Icon className="ml-11 text-[#333] dark:text-darkwhite"/>
                                        </div>
                                    </div>
                                </div>
                            }
                            <TopBarButton>
                                <a onClick={logout}>
                                    <OutputIcon className="dark:text-darklightgray text-[#333]"  sx={{fontSize: 18}}/>
                                </a>
                            </TopBarButton>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export const SubTopBar = ({children}) => {

    const isMobile = useMediaQuery('(min-width: 1024px)')
    const isSmallMobile = useMediaQuery('(min-width: 550px)')

    return (
        <div className={`${isMobile ? "pl-72" : isSmallMobile ? "pl-20" : "pl-10 pt-[16px]"} dark:bg-darkhardgray flex gap-1 w-full h-[42px] shadow-normal z-10 fixed mt-[46px] pt-[13px]`}>
            <TextDark>{children}</TextDark>
            <span className={`${isSmallMobile ? "pl-5" : "pl-2"}`}></span>
            <TextGray>Área:</TextGray>
            <TextDark>Gerencia de Sistemas</TextDark>
        </div>
    )
}