import { useState } from 'react'
import { useFetchLoad, useAsync, useMediaQuery } from '../../../hooks'
import { TextBlack, TableTextHeader, TableTextCell, TextGray } from '../../../components/Texts.component'
import { SecondaryButtonCircle } from '../../../components/Buttons.component'
import { getRolesList } from '../../../services/roles.service'
import { rolesAdapter } from '../../../adapters/roles.adapter'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import SwapVertIcon from '@mui/icons-material/SwapVert'
import SearchIcon from '@mui/icons-material/Search'
import DownloadIcon from '@mui/icons-material/Download'
import { useDispatch, useSelector } from 'react-redux'
import { setRolesModal } from '../../../redux/states/rolesModalSlice'
import { DateFormat } from '../../../utilities/datetimeFormat.utility'
import { Pagination } from '../../../components/Pagination.component'
import { CSVLink } from "react-csv"
import moment from 'moment'

export const RolesTable = () => {

    const isMobile = useMediaQuery()

    // Call roles data
    const [loading, callToFetch] = useFetchLoad()

    const [roles, setRoles] = useState([])
    const [page, setPage] = useState(0)
    const [itemsPerPage, setItemsPerPage] = useState(12)
    const { rolename } = useSelector(state => state.appState)
    
    const getApiData = async () => await callToFetch(getRolesList())
    const adaptRoles = (data) => {
        setRoles(rolesAdapter(data))
        setRolesB(rolesAdapter(data))
    }
    useAsync(getApiData, adaptRoles, () => {}, [])
    
    // Roles table data functions
    const [orderSort, setOrderSort] = useState(true)
    const sortRolesTable = (column) => {
        (orderSort)
            ? setRoles([...roles].sort((a, b) => (a[column] > b[column]) ? 1 : ((b[column] > a[column]) ? -1 : 0)))
            : setRoles([...roles].sort((a, b) => (b[column] > a[column]) ? 1 : ((a[column] > b[column]) ? -1 : 0)))
        setOrderSort(!orderSort)
    }

    const [rolesB, setRolesB] = useState([])
    const [textSearchRoles, setTextSearchRoles] = useState("")
    const searchRolesTable = e => {
        setPage(0)
        setRoles(rolesB.filter(el => Object.keys(el).some(field => el[field].toString().toLowerCase().includes(e.target.value.toLowerCase()))))
        setTextSearchRoles(e.target.value)
    }

    // Handler modal
    const dispatch = useDispatch()
    const openModal = (role) => {
        const data = {
            showModalData: true,
            showModalDeleteConfirm: false,
            modalDataIsNewRole: false,
            roleData: {
                id: role.id,
                role_name: role.role_name,
                description: role.description,
                policies: role.policies,
                create_date: role.create_date
            }
        }
        dispatch(setRolesModal(data))
    }

    const deleteRole = async (role) => {
        const data = {
            showModalData: false,
            showModalDeleteConfirm: true,
            modalDataIsNewRole: false,
            roleData: {
                id: role.id,
                role_name: role.role_name,
                description: role.description,
                policies: role.policies,
                create_date: role.create_date
            }
        }
        dispatch(setRolesModal(data))
    }

    return (
        <div className="bg-bggray dark:bg-darkdark">
            <div className="h-[60px] p-2 inline-flex items-center gap-4 flow-root">
                <div className="float-right inline-flex mt-1 gap-4 items-center mr-1">
                    <div>
                        <SearchIcon sx={{color:"#999"}} fontSize="medium"/>
                        <input placeholder="Buscar..." className="focus:outline-none dark:bg-darkdark dark:text-darklightgray focus:border-b focus:border-bblack mx-2 bg-bggray border-b border-bgray text-textblack" type="text" value={textSearchRoles} onChange={searchRolesTable}/>
                    </div>
                        <CSVLink data={roles} filename={"roles_"+ moment().format("DDMMYY_HHmm") + ".csv"}>
                            <SecondaryButtonCircle>
                                <DownloadIcon className="text-[#333] dark:text-darklightgray" sx={{fontSize:16}}/>
                            </SecondaryButtonCircle> 
                        </CSVLink>
                </div>
            </div>
            <div className={`w-auto ${!isMobile && "overflow-x-scroll"}`}>
                <table className="dark:bg-darkhardgray min-w-[600px] w-full border-collapse table-auto bg-bgwhite">
                    <thead>
                        <tr>
                            <TableTextHeader>FECHA DE CREACIÓN<SwapVertIcon onClick={() => sortRolesTable("create_date")} className="float-right hover:text-textblack" sx={{color:"#999999"}} fontSize="small"/></TableTextHeader>
                            <TableTextHeader>NOMBRE DE ROL<SwapVertIcon onClick={() => sortRolesTable("role_name")} className="float-right hover:text-textblack" sx={{color:"#999999"}} fontSize="small"/></TableTextHeader>
                            <TableTextHeader>DESCRIPCIÓN DEL ROL<SwapVertIcon onClick={() => sortRolesTable("description")} className="float-right hover:text-textblack" sx={{color:"#999999"}} fontSize="small"/></TableTextHeader>
                            <TableTextHeader>ACCIONES</TableTextHeader>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            roles.map((role, i) => (
                                <tr key={i} className={"odd:bg-bgtablecell dark:bg-darkhardgray dark:odd:bg-darkdark hover:bg-bggray dark:hover:bg-darkblack"}>
                                    <TableTextCell>{moment(role.created_at, "YYYY-MM-DD").format("DD [de] MMMM YYYY")}</TableTextCell>
                                    <TableTextCell>{role.role_name}</TableTextCell>
                                    <TableTextCell>{role.description}</TableTextCell>
                                    <td className="border dark:border-darktableborder border-bgray px-2 text-center">
                                        {rolename != role.role_name 
                                            ? <div className="flex gap-2 justify-center">
                                                <div onClick={() => openModal(role)} className="inline-flex cursor-pointer">
                                                    <EditIcon sx={{color:"#666"}} fontSize="small"/>
                                                    {isMobile && <TextBlack>Editar</TextBlack>}
                                                </div>
                                                <div onClick={() => deleteRole(role)} className="inline-flex cursor-pointer">
                                                    <DeleteIcon sx={{color:"#666"}} fontSize="small"/>
                                                    {isMobile && <TextBlack>Eliminar</TextBlack>}
                                                </div>
                                            </div>
                                            : <div className="flex gap-2 justify-center">
                                            <div className="inline-flex cursor-not-allowed">
                                                <EditIcon sx={{color:"#ccc"}} fontSize="small"/>
                                                {isMobile && <TextGray>Editar</TextGray>}
                                            </div>
                                            <div className="inline-flex cursor-not-allowed">
                                                <DeleteIcon sx={{color:"#ccc"}} fontSize="small"/>
                                                {isMobile && <TextGray>Eliminar</TextGray>}
                                            </div>
                                        </div>
                                        }
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
            <Pagination items={roles.length} page={page} setPage={setPage} itemsPerPage={itemsPerPage}/>
        </div>
    )
}