import moment from 'moment'
import 'moment/locale/es' 
moment.locale('es')

export const DatetimeFormat = (datetime) => {
    return moment(datetime, "DD/MM/YYYY HH:mm:ss").format("DD [de] MMMM YYYY HH:mm")
}

export const DateFormat = (datetime) => {
    return moment(datetime).format("DD [de] MMMM YYYY")
}

export const DateFormatShort = (datetime) => {
    return moment(datetime).format("DD MMMM YYYY")
}