import axios from 'axios'
import { loadAbort } from '../utilities/load-abort.utility'
const apiUrl = "https://g7emytzwaa.execute-api.us-east-1.amazonaws.com/dev/auth/"
const roleUrl = apiUrl + 'roles/'


export const getRolesList = () => {
    const controller = loadAbort()
    const config = {
        params: {offset: 0},
        headers: {"Content-Type": "application/json"},
        signal: controller.signal
    }
    return {
        call: axios.get(roleUrl, config),
        controller
    }
}

export const addRoles = ({role_name, description, policies}) => {
    const controller = loadAbort()
    const body = {role_name, description, policies}
    const config = {
        headers: {"Content-Type": "application/json"},
        signal: controller.signal
    }
    return {
        call: axios.post(roleUrl, body, config),
        controller
    }
}

export const editRoles = (role_id, {role_name, description, policies}) => {
    const controller = loadAbort()
    const body = {role_name, description, policies}
    const config = {
        headers: {"Content-Type": "application/json"},
        signal: controller.signal
    }
    return {
        call: axios.put(roleUrl + role_id, body, config),
        controller
    }
}

export const deleteRoles = (role_id) => {
    const controller = loadAbort()
    const config = {
        signal: controller.signal
    }
    return {
        call: axios.delete(roleUrl + role_id, config),
        controller
    }
}