import axios from 'axios'
import { loadAbort } from '../utilities/load-abort.utility'
const apiUrl = "https://g7emytzwaa.execute-api.us-east-1.amazonaws.com/dev/"
const logsUrl = apiUrl + 'metrics/'

export const getBackupsStatusGraphics = (database = null) => {
    const controller = loadAbort()
    const config = {
        params: {backupsbystatus: true},
        signal: controller.signal
    }
    if (database) config.params.database = database
    return {
        call: axios.get(logsUrl, config),
        controller
    }
}


export const getRestorationStatusGraphics = (database = null) => {
    const controller = loadAbort()
    const config = {
        params: {restorationbystatus: true},
        signal: controller.signal
    }
    if (database) config.params.database = database
    return {
        call: axios.get(logsUrl, config),
        controller
    }
}

export const getBackupsSizeGraphics = () => {
    const controller = loadAbort()
    const config = {
        signal: controller.signal
    }
    return {
        call: axios.get(logsUrl + "size", config),
        controller
    }
}

export const getStorageSize = () => {
    const controller = loadAbort()
    const config = {
        signal: controller.signal
    }
    return {
        call: axios.get(logsUrl + "storage", config),
        controller
    }
}