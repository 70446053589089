import { TopBar, SubTopBar, SideBarLeft, Body } from '../../components/Layout'
import { LightTitle } from '../../components/Texts.component'
import { LogsContent } from './components/LogsContent.component'

const Logs = () => {
    
    return (
        <>
            <TopBar/> 
            <SideBarLeft/>
            <SubTopBar>{"Administración > Logs"}</SubTopBar>
            <Body>
                <LightTitle>Auditoría</LightTitle>
                <LogsContent/>
            </Body>
        </>
    )
}

export default Logs