import { useState } from 'react'
import { TextSmallBlack, TextSmallGray } from '../../../components/Texts.component'
import { useFetchLoad, useAsync } from '../../../hooks'
import { getBackupsList } from '../../../services/backups.service'
import { lastBackupsAdapter } from '../../../adapters/backups.adapter'
import RefreshIcon from '@mui/icons-material/Refresh'
import moment from 'moment-timezone'

export const DetailsContentInfo = ({database}) => {

    const [loading, callToFetch] = useFetchLoad()
    const [info, setInfo] = useState({})

    const getApiData = async () => await callToFetch(getBackupsList(database))
    const adaptBackups = data => setInfo(lastBackupsAdapter(data))
    useAsync(getApiData, adaptBackups, () => {}, [])

    return (
        <div className="flex justify-center">
            {loading
                ? <RefreshIcon sx={{fontSize: 30}} className="mt-10 dark:text-darklightgray animate-spin text-gray"/>
                : <table className={`${loading && "hidden"} min-w-[270px] w-full border-collapse table-auto dark:bg-darkhardgray bg-bgwhite`}>
                    <tbody>
                        <tr>
                            <td><TextSmallGray>Base de datos</TextSmallGray></td>
                            <td><TextSmallBlack>{info.Database}</TextSmallBlack></td>
                        </tr>
                        <tr>
                            <td><TextSmallGray>Ultimo respaldo</TextSmallGray></td>
                            <td><TextSmallBlack>{info.BackupId}</TextSmallBlack></td>
                        </tr>
                        <tr>
                            <td><TextSmallGray>Fecha de respaldo</TextSmallGray></td>
                            <td><TextSmallBlack>{info?.StartDate != undefined && moment.utc(info.StartDate, "YYYY-MM-DD").tz("America/Santiago").local().format("DD [de] MMMM YYYY")}</TextSmallBlack></td>
                        </tr>
                        <tr>
                            <td><TextSmallGray>Status</TextSmallGray></td>
                            <td><TextSmallBlack>{info.Status}</TextSmallBlack></td>
                        </tr>
                        <tr>
                            <td className="flex w-[110px]"><TextSmallGray>Checksum</TextSmallGray></td>
                            <td><TextSmallBlack>{info.Checksum}</TextSmallBlack></td>
                        </tr>
                    </tbody>
                </table>
            }
        </div>
    )
}