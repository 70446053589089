import { useMediaQuery } from '../../hooks'

export const Body = ({children}) => {

    const isMobile = useMediaQuery('(min-width: 1024px)')
    const isSmallMobile = useMediaQuery('(min-width: 550px)')

    return (
        <div className={`${(isMobile) ? "pl-72" : (!isSmallMobile) ? "pl-8 pr-2" : "pl-14 md:pl-20"} dark:bg-darkblack overflow-scroll transition-all ease duration-200 bg-bggray w-full h-full pt-7 pr-4 pb-32 md:pr-8 mt-[89px] fixed`}>
            {children}
        </div>
    )
}