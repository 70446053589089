import { useEffect, useState } from 'react'
import { useFetchLoad, useAsync, useMediaQuery } from '../../hooks'
import { TableTextHeader, TableTextCell, TextBlack } from '../Texts.component'
import { SecondaryButtonCircle } from '../Buttons.component'
import { getRestorationsList } from '../../services/restorations.service'
import { restorationsAdapter, restorationUpdateAdapter } from '../../adapters/restorations.adapter'
import SwapVertIcon from '@mui/icons-material/SwapVert'
import SearchIcon from '@mui/icons-material/Search'
import DownloadIcon from '@mui/icons-material/Download'
import StorageIcon from '@mui/icons-material/Storage'
import { Pagination } from '../Pagination.component'
import { CSVLink } from "react-csv"
import { Link } from 'react-router-dom'
import { ProgressBar } from './ProgressBarRestoration.component'
import moment from 'moment-timezone'

export const RestorationsTable = ({numberRows, database = null}) => {

    const isMobile = useMediaQuery()

    // Call Restorations data
    const [loading, callToFetch] = useFetchLoad()
    
    const [restorations, setRestorations] = useState([])
    const [page, setPage] = useState(0)
    const [itemsPerPage, setItemsPerPage] = useState(numberRows)
    const [rows, setRows] = useState(0)
    
    const getApiData = async () => await callToFetch(getRestorationsList(database))
    const adaptRestorations = data => {
        const [totalCount, records] = restorationsAdapter(data)
        setRestorations(records)
        setRestorationsB(records)
        setRows(totalCount)
    }
    useAsync(getApiData, adaptRestorations, () => {}, [])

    // Restorations table data functions
    const [orderSort, setOrderSort] = useState(true)
    const sortRestorationsTable = column => {
        (orderSort)
        ? setRestorations([...restorations].sort((a, b) => (a[column] > b[column]) ? 1 : ((b[column] > a[column]) ? -1 : 0)))
        : setRestorations([...restorations].sort((a, b) => (b[column] > a[column]) ? 1 : ((a[column] > b[column]) ? -1 : 0)))
        setOrderSort(!orderSort)
    }
    
    const [restorationsB, setRestorationsB] = useState([])
    const [textSearchRestorations, setTextSearchRestorations] = useState("")
    const [dateSearchRestorations, setDateSearchRestorations] = useState({start:null, end:null})
    const searchRestorationsTable = e => {
        setPage(0)
        setRestorations(restorationsB.filter(obj => Object.keys(obj).some(field => obj[field].toString().toLowerCase().includes(e.target.value.toLowerCase()))))
        setTextSearchRestorations(e.target.value)
    }


    const [intervalId, setIntervalId] = useState(null);
    const adaptUpdateRestorations = (newData, data) => {
        const [totalCount, records] = restorationUpdateAdapter(newData, data)
        const [totalCountB, recordsB] = restorationsAdapter(newData)
        setRestorations(records)
        setRestorationsB(recordsB)
        setRows(totalCountB)
    }
    
    useEffect(() => {
        let isActive = true
        if (!!restorations.find(r => r.status == "En Ejecución")) {
            const call = setInterval(() => {
                getApiData().then(res => {
                    if (isActive) {
                        (restorations.length > 0 ) ? adaptUpdateRestorations(res.data, restorations) : adaptRestorations(res.data)
                    }
                })
            }, 60000);
            setIntervalId(call)
        }
        return () => {
            clearInterval(intervalId)
            isActive = false
        }
    }, [restorations]);
  
    return (
        <div className="bg-bggray dark:bg-darkdark">
            <div className="h-[60px] p-2 inline-flex items-center gap-4 flow-root">
                <div className="float-right inline-flex mt-1 gap-4 items-center mr-1">
                    <div>
{/*                         <input className="focus:outline-none focus:border-b focus:border-bblack mx-2 bg-bggray border-b border-bgray text-textblack" type="date" value={dateSearchRestorations.start} onChange={searchRestorationsTable}/>
                        <input className="focus:outline-none focus:border-b focus:border-bblack mx-2 bg-bggray border-b border-bgray text-textblack" type="date" value={dateSearchRestorations.end} onChange={searchRestorationsTable}/> */}
                        <SearchIcon sx={{color:"#666666"}} fontSize="small"/>
                        <input placeholder="Buscar" className="dark:bg-darkdark dark:text-darklightgray focus:outline-none focus:border-b focus:border-bblack mx-2 bg-bggray border-b border-bgray text-textblack" type="text" value={textSearchRestorations} onChange={searchRestorationsTable}/>
                    </div>
                        <CSVLink data={restorations} filename={"backups_"+ moment().format("DDMMYY_HHmm") + ".csv"}>
                            <SecondaryButtonCircle>
                                <DownloadIcon className="text-[#333] dark:text-darklightgray" sx={{fontSize:16}}/>
                            </SecondaryButtonCircle> 
                        </CSVLink>
                </div>
            </div>
            <div className={`w-auto ${!isMobile && " overflow-x-scroll"}`}>
                <table className="min-w-[600px] w-full border-collapse dark:bg-darkhardgray table-auto bg-bgwhite">
                    <thead>
                        <tr>
                            <TableTextHeader>INICIO RESTAURACIÓN<SwapVertIcon onClick={() => sortRestorationsTable("start_date")} className="float-right hover:text-textblack cursor-pointer" sx={{color:"#999999"}} fontSize="small"/></TableTextHeader>
                            <TableTextHeader>IDENTIFICADOR<SwapVertIcon onClick={() => sortRestorationsTable("backup")} className="float-right hover:text-textblack cursor-pointer" sx={{color:"#999999"}} fontSize="small"/></TableTextHeader>
                            <TableTextHeader>BASE DE DATOS<SwapVertIcon onClick={() => sortRestorationsTable("database")} className="float-right hover:text-textblack cursor-pointer" sx={{color:"#999999"}} fontSize="small"/></TableTextHeader>
                            <TableTextHeader>PROGRESO</TableTextHeader>
                            <TableTextHeader>ESTADO<SwapVertIcon onClick={() => sortRestorationsTable("status")} className="float-right hover:text-textblack cursor-pointer" sx={{color:"#999999"}} fontSize="small"/></TableTextHeader>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            restorations.map((restoration, i) => (i >= (page * itemsPerPage) && i < (page * itemsPerPage) + itemsPerPage) && (
                                <tr key={i} className={"odd:bg-bgtablecell dark:bg-darkhardgray dark:odd:bg-darkdark hover:bg-bggray dark:hover:bg-darkblack"}>
                                    <TableTextCell>{moment.utc(restoration.start_date, "DD/MM/YYYY HH:mm:ss").tz("America/Santiago").local().format("DD [de] MMMM YYYY HH:mm")}</TableTextCell>
                                    <TableTextCell>{restoration.backup}</TableTextCell>
                                    <td className="border dark:border-darktableborder border-bgray px-2 text-center">
                                        <div className="flex mx-auto">
                                            <Link to={"/details/" + restoration.database} replace>
                                                <div className="inline-flex gap-1 cursor-pointer">
                                                    <StorageIcon sx={{color:"#999", marginRight:"5px"}} fontSize="small"/>
                                                    <TextBlack>{restoration.database}</TextBlack>
                                                </div>
                                            </Link>
                                        </div>
                                    </td>
                                    <ProgressBar prevStatus={restoration.prev_status} detailStatus={restoration.detail_status} lastDate={restoration.last_date} status={restoration.status} start={restoration.start_date}  value={restoration.percentage}/>
                                    <td className="h-[32px] dark:border-darktableborder border px-2 border-bgray focus:bg-bggray">
                                        <p className={`text-textblack dark:text-darklightgray text-center text-[0.8rem] md:text-[0.9rem] font-normal`}>
                                            {restoration.status}
                                        </p>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
            <Pagination items={textSearchRestorations != "" ? restorations.length : rows} page={page} setPage={setPage} itemsPerPage={itemsPerPage}/>
        </div>
    )
}