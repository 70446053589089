import axios from 'axios'
import { loadAbort } from '../utilities/load-abort.utility'
const apiUrl = "https://g7emytzwaa.execute-api.us-east-1.amazonaws.com/dev/"
const logsUrl = apiUrl + 'logging/'


export const getLogsList = () => {
    const controller = loadAbort()
    const config = {
        params: {offset: 0},
        signal: controller.signal
    }
    return {
        call: axios.get(logsUrl, config),
        controller
    }
}