import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    open_menu: true,
    open_submenu_backups: false,
    open_submenu_admin: false,
    theme: "light",
    letter_size: 1,
    username: "username",
    rolename: "rolename"
}

export const appStateSlice = createSlice({
    name: "appState",
    initialState,
    reducers: {
        setAppState: (state, action) => {
            return state = action.payload
        }
    }
})

export const { setAppState } = appStateSlice.actions
export default appStateSlice.reducer