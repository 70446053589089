import Highcharts from "highcharts/highstock"
import HighchartsReact from "highcharts-react-official"
import { useFetchLoad, useAsync } from '../../hooks'
import { getRestorationStatusGraphics } from "../../services/metrics.service"
import { circularGraphicsAdapter } from "../../adapters/metrics.adapter"
import { useState } from "react"

export const CircularGraphicRestorations = ({database = null}) => {

  const [loading, callToFetch] = useFetchLoad()
  const [chartOptions, setChartOptions] = useState()

  const getApiData = async () => await callToFetch(getRestorationStatusGraphics(database))
  const adaptBackups = data => setChartOptions({
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            backgroundColor: 'transparent',
            height: 300,
            plotShadow: false,
            type: 'pie'
        },
        accessibility: { enabled: false },
        title: null,
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: { enabled: false },
                showInLegend: true
            }
        },
        legend: {
            itemStyle: { color: "#999" }
        },
        series: [{
            name: 'Nª Respaldos',
            colorByPoint: true,
            data: circularGraphicsAdapter(data),
        }]
  })
  useAsync(getApiData, adaptBackups, () => {}, [])

    return (
        <>
            <HighchartsReact highcharts={Highcharts} options={chartOptions} />
        </>
    )
}