import { Title, TextWhite } from '../../../components/Texts.component'
import { SecondaryButtonCircle } from '../../../components/Buttons.component'
import { LogsTable } from './LogsTable.component'
import RefreshIcon from '@mui/icons-material/Refresh'
import { useState } from 'react'

export const LogsContent = () => {

    const [reloadTable, setReloadTable] = useState(0)

    return (
        <>
            <div className="w-full h-auto dark:bg-darkhardgray bg-bgwhite shadow-normal rounded p-5">
                <div className="flow-root">
                    <div className="float-left pt-2">
                        <Title>Logs de Auditoría</Title>
                    </div>
                    <div className="float-right gap-2 inline-flex">
                        <SecondaryButtonCircle onClick={() => setReloadTable(reloadTable + 1)}>
                            <RefreshIcon className="text-[#333] dark:text-darklightgray" sx={{fontSize:16}}/> 
                        </SecondaryButtonCircle> 
                    </div>
                </div>
                <hr className="border-bgray my-4"/>
                <LogsTable key={reloadTable} />
            </div>
        </>
    )
}