import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    showModalData: false,
    modalDataIsNewRole: true,
    showModalDeleteConfirm: false,
    create: false,
    update: false,
    delete: false,
    roleData: {
        id: "",
        role_name: "",
        description: "",
        policies: [],
        create_date: ""
    }
}

export const rolesModalSlice = createSlice({
    name: "rolesModal",
    initialState,
    reducers: {
        setRolesModal: (state, action) => {
            return state = action.payload
        }
    }
})

export const { setRolesModal } = rolesModalSlice.actions
export default rolesModalSlice.reducer